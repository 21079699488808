export const auth = {
    isAuthenticated: false,
    username: '',
	authenticate(cb) {
		fetch('/users/auth', {
            credentials: 'include',
        })
		.then((res) => {
            return res.json();
        })
        .then((data) => {
            this.isAuthenticated = data.authenticated;
            this.username = data.user.username;
            if (typeof cb === 'function') {
				cb();
            }
        })
		.catch((err) => {
			console.log('Error fetching authorized user.', err);
		});
	},
	signout(cb) {
		fetch('/users/logout', {
            method: 'POST',
            credentials: 'include'
		})
		.then((res) => {
            this.isAuthenticated = false; 
            this.username = '';
			if (typeof cb === 'function') {
				// user was logged out
				cb(true);
			}
		})
		.catch((err) => {
			console.log('Error logging out user.');
			if (typeof cb === 'function') {
				cb(false);
			}
		});
	}
}