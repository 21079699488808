import React from "react";
import '../Programs/Programs.scss';
import SubHeader from '../../components/SubHeader/SubHeader';
import {Link} from "react-router-dom";
import LangContext from '../../context';

export default function Programs() {
  const {programs} = React.useContext(LangContext).locale;
  return (
      <div className="programs">
        <SubHeader title={programs.header} content={programs.headerDesc}/>
        <div className="programs-box">
          {programs.desc}
          <div className="programs-box-row">
            <Link color="inherit" to="/programs/international-students">
              <img
                src="/images/pgm_international2.jpg"
                alt="program image"/>
            </Link>
            <Link color="inherit" to="/programs/pre-college-programs/">
              <img
                src="/images/pgm_k8_2.jpg"
                alt="program image"/>
            </Link>
          </div>
          <div className="programs-box-row">
            <Link color="inherit" to="/programs/k-8-students">
              <img
                src="/images/pgm_preCollege2.jpg"
                alt="program image"/>
            </Link>
            <Link color="inherit" to="/programs/tutoring">
              <img
                src="/images/pgm_tutoring2.jpg"
                alt="program image"/>
            </Link>
          </div>
        </div>
      </div>
  );
}
