import React from "react";
import "./PreCollegePrograms.scss";
import SubHeader from "../../../components/SubHeader/SubHeader";
import CollapseCard from "../../../components/CollapseCard/CollapseCard";
import LangContext from "../../../context";

export function PCollege() {
  const {precollege} = React.useContext(LangContext).locale.programs;
  return (
      <div className="pcollege">
        <SubHeader title={precollege.header} content={precollege.headerDesc} img={"/images/parallax_pre-college2.jpg"}/>
        <div className="pcollege-desc">
            <div>
                {precollege.desc.map( (ele,i) => {
                  if (Array.isArray(ele)) 
                    return (
                      <ul key={i}>{ele.map( listEle => (<li key={listEle}>{listEle}</li>))}</ul>
                    )
                  else return <p key={i}>{ele}</p>
                })}
            </div>
            <div className="image">
                <img
                    src="/images/pre-college-400x300.jpg"
                    alt="program"/>
            </div>
        </div>
        <h5>{precollege.cardTitles[0]}</h5>
        <div className="pcollege-packages">
            <div>
              {precollege.collapseCards.map( (ele, i) => (
                <CollapseCard key={i} title={ele.title} desc={ele.desc}/>
              ))}
            </div>
        </div>
      </div>
  );
}
