import React from "react";
import "./Tutoring.scss";
import SubHeader from "../../../components/SubHeader/SubHeader";
import LangContext from "../../../context";

export function Tutoring() {
  const {tutoring} = React.useContext(LangContext).locale.programs;
  return (
      <div className="tutoring">
        <SubHeader title={tutoring.header} content={tutoring.headerDesc} img={"/images/parallax_tutoring2.jpg"}/>
        <div className="tutoring-desc">
            <div>
              {tutoring.cards.slice(0,2).map( (card,i) => (
                <div key={i} className="desc-block">
                  <h5>{card.title}</h5>
                  {card.desc.map( (ele,i) => {
                    if (Array.isArray(ele)) 
                      return (
                        <ul key={i}>{ele.map( listEle => (<li key={listEle}>{listEle}</li>))}</ul>
                      )
                    else if (ele instanceof Object) 
                      return (
                        <h5 key={i}>{ele.title}</h5>
                      )
                    else return <p key={i}>{ele}</p>
                  })}
                </div>
              ))}
            </div>
            <div className="image">
                <img
                    src="/images/individual_tutoring-400x300.jpg"
                    alt="program"/>
                {tutoring.cards.slice(2,3).map( (card,i) => (
                  <div key={i} className="desc-block">
                    <h5>{card.title}</h5>
                    {card.desc.map( (ele,i) => {
                      if (Array.isArray(ele)) 
                        return (
                          <ul key={i}>{ele.map( listEle => (<li key={listEle}>{listEle}</li>))}</ul>
                        )
                      else if (ele instanceof Object) 
                        return (
                          <h5 key={i}>{ele.title}</h5>
                        )
                      else return <p key={i}>{ele}</p>
                    })}
                  </div>
                ))}
            </div>
        </div>
        <div className="tutoring-desc">
            <div className="image">
                <img
                    src="/images/in-person-400x300.jpg"
                    alt="program"/>
            </div>
            <div>
              {tutoring.cards.slice(3).map( (card,i) => (
                <div key={i} className="desc-block">
                  <h5>{card.title}</h5>
                  {card.desc.map( (ele,i) => {
                    if (Array.isArray(ele)) 
                      return (
                        <ul key={i}>{ele.map( listEle => (<li key={listEle}>{listEle}</li>))}</ul>
                      )
                    else if (ele instanceof Object) 
                      return (
                        <h5 key={i}>{ele.title}</h5>
                      )
                    else return <p key={i}>{ele}</p>
                  })}
                </div>
              ))}
            </div>
        </div>
      </div>
  );
}
