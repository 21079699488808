import React from "react";
import moment from "moment";
import "./Application.scss";
import SubHeader from "../../../../components/SubHeader/SubHeader";
import { Form } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import LangContext from '../../../../context';

const EDU_STATUS = [
    {value:0, text:"A citizen/resident currently studying in the US"},
    {value:1, text:"An international student planning to study in the US"},
    {value:2, text:"An international student already studying in the US"},
]
const COUNTRYS = [
    {value:0, text:"USA"},
    {value:1, text:"China"},
    {value:2, text:"Canada"},
    {value:3, text:"Other"},
]
const EDU_LEVEL = [
    {value:0, text:"Middle School"},
    {value:1, text:"High School"},
    {value:2, text:"Colege"},
]
const GRADE_LEVEL = [
    {value:0, text:"Grade 6"},
    {value:1, text:"Grade 7"},
    {value:2, text:"Grade 8"},
    {value:3, text:"Grade 9"},
    {value:4, text:"Grade 10"},
    {value:5, text:"Grade 11"},
    {value:6, text:"Grade 12"},
    {value:7, text:"Grade 13"},
    {value:8, text:"College Freshman"},
    {value:9, text:"College Sophomore"},
    {value:10, text:"College Junior"},
    {value:11, text:"College Senior"},

]

function StudentInfo(props) {
    const {studentForm} = React.useContext(LangContext).locale.programs.istudents.application;
    return (
        <Card>
            <CardContent>
                <h4 className="card-title">{studentForm.title}</h4>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <TextField
                                select
                                label={studentForm.submitter}
                                value={props.formVal["submitType"]}
                                onChange={e => props.setFormVal(e, "submitType")}
                                variant="outlined">
                                {studentForm.submitOptions.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.text}
                                    </MenuItem>
                                ))}
                                </TextField>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Col>
                    <Col>
                        <TextField
                        id="studentName"
                        label={studentForm.studentName}
                        value={props.formVal["studentName"]}
                        onChange={props.setFormVal}
                        required
                        variant="outlined"/>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>
                        <TextField
                        id="parentName"
                        label={studentForm.parentName}
                        value={props.formVal["parentName"]}
                        onChange={props.setFormVal}
                        variant="outlined"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                        id="address1"
                        label={studentForm.address1}
                        value={props.formVal["address1"]}
                        onChange={props.setFormVal}
                        required
                        variant="outlined"/>
                    </Col>
                    <Col>
                        <TextField
                        id="mainEmail"
                        label={studentForm.mailEmail}
                        value={props.formVal["mainEmail"]}
                        onChange={props.setFormVal}
                        required
                        variant="outlined"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                        id="address2"
                        label={studentForm.address2}
                        value={props.formVal["address2"]}
                        onChange={props.setFormVal}
                        variant="outlined"/>
                    </Col>
                    <Col>
                        <TextField
                        id="altEmail"
                        label={studentForm.altEmail}
                        value={props.formVal["altEmail"]}
                        onChange={props.setFormVal}
                        variant="outlined"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                        id="city"
                        label={studentForm.city}
                        value={props.formVal["city"]}
                        onChange={props.setFormVal}
                        required
                        variant="outlined"/>
                    </Col>
                    <Col>
                        <TextField
                        id="phone"
                        label={studentForm.phone}
                        value={props.formVal["phone"]}
                        onChange={props.setFormVal}
                        variant="outlined"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <TextField
                                id="state"
                                label={studentForm.state}
                                value={props.formVal["state"]}
                                onChange={props.setFormVal}
                                required
                                variant="outlined"/>
                            </Col>
                            <Col>
                                <TextField
                                id="zip"
                                label={studentForm.zip}
                                value={props.formVal["zip"]}
                                onChange={props.setFormVal}
                                required
                                variant="outlined"/>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <TextField
                        id="parentPhone"
                        label={studentForm.parentPhone}
                        value={props.formVal["parentPhone"]}
                        onChange={props.setFormVal}
                        variant="outlined"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <TextField
                                select
                                label={studentForm.country}
                                value={props.formVal["country"]}
                                onChange={e => props.setFormVal(e, "country")}
                                variant="outlined">
                                {studentForm.countryOptions.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.text}
                                    </MenuItem>
                                ))}
                                </TextField>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <TextField
                        id="studentPhone"
                        label={studentForm.studentPhone}
                        value={props.formVal["studentPhone"]}
                        onChange={props.setFormVal}
                        variant="outlined"/>
                    </Col>
                </Row>
            </CardContent>
        </Card>
    )
}

function StatusInfo(props) {
    const {statusForm} = React.useContext(LangContext).locale.programs.istudents.application;
    const {status} = React.useContext(LangContext).locale.programs.istudents.application;
    return (
        <Card>
            <CardContent>
                <h4 className="card-title">{status.options[props.status].text}</h4>
                <Row>
                    { props.status === 1 ?
                    <Col>
                    <Row>
                        <Col>
                            <TextField
                                select
                                label={statusForm.eduLevel}
                                value={props.formVal["startLevel"]}
                                onChange={e => props.setFormVal(e, "startLevel")}
                                variant="outlined">
                                {statusForm.eduOptions.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.text}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Col>
                        <Col></Col>
                    </Row>
                    </Col> : null }
                    <Col>
                        <Row>
                            <Col>
                                <FormControl component="fieldset">
                                <FormLabel component="legend">{statusForm.visaStatus}</FormLabel>
                                <RadioGroup value={parseInt(props.formVal["visaStatus"])} onChange={e=>{props.setFormVal(e, "visaStatus")}}>
                                    {statusForm.visaOptions.map(option => (
                                        <FormControlLabel
                                            key={option.value}
                                            value={option.value}
                                            control={<Radio />}
                                            label={option.text}/>
                                    ))}
                                </RadioGroup>
                                </FormControl>
                            </Col>
                            <Col>
                                { parseInt(props.formVal["visaStatus"]) < 3 ? 
                                    <TextField
                                        id="expiredDate"
                                        label={statusForm.visaExpiry}
                                        type="date"
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        value={props.formVal["expiredDate"]}
                                        onChange={props.setFormVal}
                                    /> : null }
                            </Col>
                        </Row>
                    </Col> 
                </Row>
                {props.status === 1 ?
                <Row>
                    <Col>
                    <Row>
                        <Col>
                            <TextField
                            id="beginYear"
                            label={statusForm.beginYear}
                            value={props.formVal["beginYear"]}
                            onChange={props.setFormVal}
                            variant="outlined"/>
                        </Col>
                        <Col>
                            <FormControl component="fieldset">
                            <FormLabel component="legend">{statusForm.semesterTitle}</FormLabel>
                            <RadioGroup value={parseInt(props.formVal["startSeason"])} onChange={e=>{props.setFormVal(e, "startSeason")}}>
                                {statusForm.semesterOptions.map(option => (
                                    <FormControlLabel
                                        key={option.value}
                                        value={option.value}
                                        control={<Radio />}
                                        label={option.text}/>
                                ))}
                            </RadioGroup>
                            </FormControl>
                        </Col>
                    </Row>
                    </Col>
                    <Col>
                        { props.formVal["startLevel"] === 2 ?
                            <TextField
                            id="major"
                            label={statusForm.major}
                            value={props.formVal["major"]}
                            onChange={props.setFormVal}
                            variant="outlined"/> : null}
                    </Col>
                </Row> : null}
            </CardContent>
        </Card>
    )
}

function EducationInfo(props) {
    const {educationForm} = React.useContext(LangContext).locale.programs.istudents.application;
    return (
        <Card>
            <CardContent>
                <h4 className="card-title">{educationForm.title}</h4>
                <h5 className="card-title">{educationForm.subTitle1}</h5>
                <Row>
                    <Col>
                        <Row>
                        <Col>
                        <TextField
                            select
                            label={educationForm.eduLevel}
                            value={props.formVal["educationLevel"]}
                            onChange={e => props.setFormVal(e, "educationLevel")}
                            variant="outlined">
                            {educationForm.eduOptions.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.text}
                                </MenuItem>
                            ))}
                        </TextField>
                        </Col>
                        <Col></Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <TextField
                                    select
                                    label={educationForm.gradeLevel}
                                    value={props.formVal["gradeLevel"]}
                                    onChange={e => props.setFormVal(e, "gradeLevel")}
                                    variant="outlined">
                                    {educationForm.gradeOptions.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.text}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col>
                                <TextField
                                id="GPA"
                                label={educationForm.gpa}
                                value={props.formVal["GPA"]}
                                onChange={props.setFormVal}
                                required
                                variant="outlined"/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <h5 className="card-title">{educationForm.subTitle2}</h5>
                <Row>
                    <Col>
                        <TextField
                        id="schoolName"
                        label={educationForm.school}
                        value={props.formVal["schoolName"]}
                        onChange={props.setFormVal}
                        required
                        variant="outlined"/>
                    </Col>
                    <Col>
                    <Row>
                        <Col>
                            <TextField
                                select
                                label={educationForm.country}
                                value={props.formVal["country"]}
                                onChange={e => props.setFormVal(e, "country")}
                                variant="outlined">
                                {educationForm.countryOptions.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.text}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Col>
                        <Col></Col>
                    </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                        id="address1"
                        label={educationForm.address1}
                        value={props.formVal["address1"]}
                        onChange={props.setFormVal}
                        required
                        variant="outlined"/>
                    </Col>
                    <Col>
                        <TextField
                        id="city"
                        label={educationForm.city}
                        value={props.formVal["city"]}
                        onChange={props.setFormVal}
                        required
                        variant="outlined"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                        id="address2"
                        label={educationForm.address2}
                        value={props.formVal["address2"]}
                        onChange={props.setFormVal}
                        variant="outlined"/>
                    </Col>
                    <Col>
                    <Row>
                        <Col>
                            <TextField
                            id="state"
                            label={educationForm.state}
                            value={props.formVal["state"]}
                            onChange={props.setFormVal}
                            required
                            variant="outlined"/>
                        </Col>
                        <Col>
                            <TextField
                            id="zip"
                            label={educationForm.zip}
                            value={props.formVal["zip"]}
                            onChange={props.setFormVal}
                            required
                            variant="outlined"/>
                        </Col>
                    </Row>
                    </Col>
                </Row>
                {props.hasInterest !== 1 ? <h5 className="card-title">{educationForm.interestTitle}</h5> : null}
                {props.hasInterest !== 1? <p className="card-title">{educationForm.interestSubTitle}</p> : null }
                {props.hasInterest === 0 ?
                    <Row>
                    {["collegeAdvising", "subjectTutoring", "other"].map( (option, i) => (
                        <FormControlLabel
                            key={i}
                            control={
                            <Checkbox
                                checked={props.formVal[option]}
                                onChange={e => props.setFormVal(e, option, "checked")}
                                color="primary"
                            />
                            }
                            label={educationForm.interestSubjects[0][i]}
                        />
                    ))}</Row> : null}
                {props.hasInterest === 2 ?
                    <Row>
                    {["carePackages", "appeals", "legal"].map( (option, i) => (
                        <FormControlLabel
                            key={i}
                            control={
                            <Checkbox
                                checked={props.formVal[option]}
                                onChange={e => props.setFormVal(e, option, "checked")}
                                color="primary"
                            />
                            }
                            label={educationForm.interestSubjects[1][i]}
                        />
                    ))}</Row> : null}
            </CardContent>
        </Card>
    )
}

const useFormFields = initialState => {
    const [fields, setValues] = React.useState(initialState);
    return [
        fields,
        function(event, id, val) {
            console.log(id, fields["visaStatus"], event.target.value)
            setValues({
                ...fields,
                [id || event.target.id]: val ? event.target[val] : event.target.value
            });
        }
    ];
}

export function IApplication() {
    const [studentForm, setStudentForm] = useFormFields({
        submitType: 0,
        studentName: "",
        parentContact: "",
        address1: "",
        address2: "",
        mainEmail: "",
        altEmail: "",
        city: "",
        homePhone: "",
        state: "",
        zip: "",
        parentPhone: "",
        studentPhone: "",
        country: 0,
    });
    const [status, setStatus] = React.useState(1);
    const [statusForm, setStatusForm] = useFormFields({
        startLevel: 0,
        visaStatus: 0,
        expiredDate: moment().format("YYYY-MM-DD"),
        startYear: "",
        startSeason: 0,
        major: "",
    });
    const [educationForm, setEducationForm] = useFormFields({
        educationLevel: 0,
        gradeLevel: 0,
        GPA: "",
        schoolName: "",
        country: 0,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",  
        collegeAdvising: false,
        subjectTutoring: false,
        other: false,
        carePackages: false,
        appeals:false,
        legal:false,
    });
    const [comments, setComments] = React.useState("");

    const handleSubmit = e => {
        e.preventDefault();
        console.log(statusForm);
    }

    const {application} = React.useContext(LangContext).locale.programs.istudents;

    return (
    <div className="iapplication">
        <SubHeader title={application.header} content={application.headerDesc}/>
        <p className="iapplication-desc">Add additional descriptive text explaining the International Student application form…</p>
        <div className="iapplication-form">
            <Form onSubmit={handleSubmit}>
                <StudentInfo formVal={studentForm} setFormVal={setStudentForm}/>
                <Row>
                    <Col>
                        <TextField
                        select
                        label={application.status.title}
                        value={status}
                        onChange={e => setStatus(e.target.value)}
                        variant="outlined">
                        {application.status.options.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.text}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Col>
                    <Col></Col>
                </Row>
                {status !== 0 ? <StatusInfo status={status} formVal={statusForm} setFormVal={setStatusForm}/> : null}
                <EducationInfo hasInterest={status} formVal={educationForm} setFormVal={setEducationForm}/>
                <Card>
                    <CardContent>
                        <h4 className="card-title">{application.comment.title}</h4>
                        <Form.Control 
                            as="textarea"
                            rows="3"
                            placeholder={application.comment.placeHolder}
                            value={comments}
                            onChange={e=>setComments(e.target.value)}/>
                    </CardContent>
                </Card>
                <Button variant="contained" type="submit">Submit</Button>
            </Form>
        </div>
    </div>
    );
}
