import React from "react";
import './Contact.scss';
import SubHeader from '../../components/SubHeader/SubHeader';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import Form from 'react-bootstrap/Form'
import Button from '@material-ui/core/Button';
import LangContext from '../../context';

export default function Contact() {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [subject, setSubject] = React.useState('');
    const [message, setMessage] = React.useState('');
    const {contact} = React.useContext(LangContext).locale;

    function validateForm() {
        return name.length > 0 && email.length > 0 && message.length > 0;
    }

    return (
        <div className="contact">
            <SubHeader title={contact.header} content={contact.headerDesc}/>
            <div className="contact-box">
                <div className="contact-box-content">
                    <h3>{contact.title}</h3>
                    <Form className="contact-box-content-form">
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>{contact.name}</Form.Label>
                            <Form.Control
                                placeholder={contact.nameHolder}
                                value={name}
                                onChange={e => setName(e.target.value)}/>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput2">
                            <Form.Label>{contact.email}</Form.Label>
                            <Form.Control 
                                type="email"
                                placeholder={contact.emailHolder}
                                value={email}
                                onChange={e => setEmail(e.target.value)}/>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput3">
                            <Form.Label>{contact.subject}</Form.Label>
                            <Form.Control
                                value={subject}
                                onChange={e => setSubject(e.target.value)}/>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>{contact.message}</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="3"
                                value={message}
                                onChange={e => setMessage(e.target.value)}/>
                        </Form.Group>
                        <Button variant="contained" disabled={!validateForm()} type="submit">
                            {contact.btn}
                        </Button>
                    </Form>
                </div>
                <div className="contact-box-info">
                    <div className="contact-box-info-title">{contact.info}</div>
                    <div className="contact-box-info-address">
                      {contact.address.map( e => 
                        <span key={e}>{e}<br/></span>
                      )}
                    </div>
                      {contact.phone.map( e => 
                      <div key={e} className="contact-box-info-phone">
                        <PhoneIcon/>{e}
                      </div>
                      )}
                    <div className="contact-box-info-email">
                        <MailIcon/>
                        <a href="mailto:contact@flexeducationinc.com">contact@flexeducationinc.com</a>
                    </div>
                      <div className="contact-box-info-time">{contact.time}</div>
                    <div className="contact-box-info-map"></div>
                </div>
            </div>
        </div>
    )
}
