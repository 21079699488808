import React from "react";
import './Home.scss';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import PublicIcon from '@material-ui/icons/Public';
import SchoolIcon from '@material-ui/icons/School';
import StudentIcon from '@material-ui/icons/AccessibilityNew';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import LangContext from '../../context';
import {Link} from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

function HomeCarousel() {
    const [index, setIndex] = React.useState(0);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
  
    return (
      <Carousel 
        prevIcon={<ArrowBackIosIcon color="disabled" style={{ fontSize: 40 }}/>}
        nextIcon={<ArrowForwardIosIcon color="disabled" style={{ fontSize: 40 }}/>}
        interval={3000} className="home-carousel" activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item className="home-carousel-item">
        <Link color="inherit" to="/programs/international-students">
          <Image
            className="d-block w-100"
            src="images/carousel1.png"
            alt="First slide"
            fluid
          />
        </Link>
        </Carousel.Item>
        <Carousel.Item className="home-carousel-item">
        <Link color="inherit" to="/programs/k-8-students">
          <Image
            className="d-block w-100"
            src="images/carousel2.png"
            alt="Second slide"
            fluid
          />
        </Link>
        </Carousel.Item>
        <Carousel.Item className="home-carousel-item">
        <Link color="inherit" to="/programs/pre-college-programs">
          <Image
            className="d-block w-100"
            src="images/carousel3.png"
            alt="Third slide"
            fluid
          />
        </Link>
        </Carousel.Item>
      </Carousel>
    );
}

function ProgramBox(props) {
  return (
    <div className="home-program-box">
      <div className="home-program-box-icon">{props.children}</div>
      <div className="home-program-box-title">{props.title}</div>
      <div className="home-program-box-content">{props.content}</div>
      <div className="home-program-box-btn">
        <Link color="inherit" to={props.url} >
        <Button variant="contained">
            {props.btnText}
        </Button>
        </Link>
      </div>
    </div>
  )
}

export default function Home() {
  const {locale} = React.useContext(LangContext);
  const iconLists = [<PublicIcon/>, <SchoolIcon/>, <StudentIcon/>, <SettingsIcon/>];
  const navUrls = ["/programs/international-students", "/programs/pre-college-programs", "/programs/k-8-students", "/programs/tutoring"]
  return (
    <div className="home">
      <HomeCarousel/>
      <div className="home-program">
        {locale.main.sections.map( (e,i) => (
          <ProgramBox
            key={e.title}
            title={e.title}
            content={e.desc}
            btnText={e.btn}
            url={navUrls[i]}>
              {iconLists[i]}
          </ProgramBox>
        ))}
      </div>
      <div className="home-footer">
        <div className="home-footer-wrapper">
          <div className="home-footer-title">{locale.main.footer.title}</div>
        <div className="home-footer-content">{locale.main.footer.desc}</div>
        </div>
      </div>
    </div>
  )
}
