import React from "react";
import './InternationalStudents.scss';
import SubHeader from '../../../components/SubHeader/SubHeader';
import {Link} from "react-router-dom";
import LangContext from '../../../context';

export function IStudents() {
  const {general} = React.useContext(LangContext).locale.programs.istudents;
  return (
      <div className="istudent">
        <SubHeader title={general.header} content={general.headerDesc}/>
        <div className="istudent-desc">
            <div>
                {general.desc.map( (ele,i) =>{
                    if (Array.isArray(ele)) 
                    return (
                      <ul key={i}>{ele.map( listEle => (<li key={listEle}>{listEle}</li>))}</ul>
                    )
                    else return <p key={i}>{ele}</p>
                })}              
            </div>
            <div className="image">
                <img
                    src="/images/international-student-400x400.jpg"
                    alt="program image"/>
            </div>
        </div>
        <div className="istudent-links">
            <Link to="/programs/international-students/international-highschool">
                <div className="image">
                    <img
                        src="/images/banner_highSchool3.jpg"
                        alt="program image"/>
                </div>
            </Link>
            <Link to="/programs/international-students/international-college">
                <div className="image">
                    <img
                        src="/images/banner_college4.jpg"
                        alt="program image"/>
                </div>
            </Link>
        </div>
      </div>
  );
}
