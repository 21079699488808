export const TEST_QUESTIONS = [
    {
      "sequence": 1,
      "title": "1. 对年满21岁以上的驾驶者而言﹐合法的血液酒精浓度(BAC)是多少?",
      "option_list": [
        "百分之零点八(0.80)",
        "百分之零点零八(0.08)",
        "百分之零点零五(0.05)"
      ],
      "answer": "3"
    },
    {
      "sequence": 2,
      "title": "2. 有关煞车的研究表明﹐以时速55英里速度行驶的大型卡车所杀车距离为一般汽车的___.",
      "option_list": [
        "同样的距离",
        "俩倍",
        "三倍"
      ],
      "answer": "2"
    },
    {
      "sequence": 3,
      "title": "3. 在雾中或雨中驾车时﹐最好﹕",
      "option_list": [
        "经常使用您的泊车讯号灯",
        "经常使用您的紧急讯号灯",
        "打开您的前灯"
      ],
      "answer": "3"
    },
    {
      "sequence": 4,
      "title": "4. 在销售或转让您的车辆的多少天之内您必需向DMV报告﹖",
      "option_list": [
        "5天",
        "10天",
        "15天"
      ],
      "answer": "1"
    },
    {
      "sequence": 5,
      "title": "5. 如果您遇到红色交通信号灯﹐但一位治安官员或消防队员指示您继续驾驶﹐您应该﹕",
      "option_list": [
        "等待录色讯号灯",
        "按照其指示作",
        "将车使至路缘并停车"
      ],
      "answer": "2"
    },
    {
      "sequence": 6,
      "title": "6. 涂上色的路缘意味停车或泊车是﹕",
      "option_list": [
        "仅供救急车辆用",
        "仅供乘客用",
        "仅限于一定的时期一要看标志或街道标记标明的时限"
      ],
      "answer": "3"
    },
    {
      "sequence": 7,
      "title": "7. 当来到有停车标志的转交处﹐您首先必需停车﹕",
      "option_list": [
        "在距离行人横道线四英尺处﹐然后您可进入交叉路口",
        "在足以看到两边来往交通的地方",
        "在行人横道线前"
      ],
      "answer": "3"
    },
    {
      "sequence": 8,
      "title": "8. 如果发生车祸﹐并且其中一辆车所受损失超过$500﹐在以下情况下﹐您必需向DMV提出意外报告(SR1)",
      "option_list": [
        "只有在您有过错的情况下",
        "无论是谁的错",
        "只有在另一辆车中有人受伤或死亡的情况下"
      ],
      "answer": "2"
    },
    {
      "sequence": 9,
      "title": "9. 正确系上的座椅安全带﹕",
      "option_list": [
        "是法律的规定",
        "将防止车祸",
        "将制止您移动您的身体"
      ],
      "answer": "1"
    },
    {
      "sequence": 10,
      "title": "10. 在进入一条车道前﹐为确保该车道是通畅无阻的您应该﹕",
      "option_list": [
        "转头观察您想进入的车道",
        "看左侧反光镜",
        "看后向反光镜"
      ],
      "answer": "1"
    },
    {
      "sequence": 11,
      "title": "11. 如果您被判乱扔废弃物﹐这一判决是否会记入您的驾驶档案﹖",
      "option_list": [
        "是﹐如果您未满18岁",
        "是﹐无论您的年龄多大",
        "否﹐除非您年满18岁以上"
      ],
      "answer": "2"
    },
    {
      "sequence": 12,
      "title": "12. 当您舆其他车辆同时到一个没有交通信号灯或停车标志的转交时﹐您应该给以下哪一个人先行权﹕",
      "option_list": [
        "您右边的驾驶者",
        "您左边驾驶者",
        "您对面的驾驶者"
      ],
      "answer": "1"
    },
    {
      "sequence": 13,
      "title": "13. “默示同意的法则”指明您已同意在下述情况下对您的血液酒精浓度进行检验﹕",
      "option_list": [
        "您在加州驾车的任何时候",
        "经律师同意",
        "如果发生了车祸"
      ],
      "answer": "1"
    },
    {
      "sequence": 14,
      "title": "14. 在转角处横越街道的行人在以下情况下有先行权﹕",
      "option_list": [
        "只有在街道上划出行人在以下情况下有先行权",
        "只有在行人交通管制装置运作正常的情况下",
        "无论街道上是否划出行人横道线",
        "在管制车辆交通的讯号灯失灵的情况下"
      ],
      "answer": "3"
    },
    {
      "sequence": 15,
      "title": "15. 为避免突然的“最后一刻行动”﹐在扫视道路看是否有危险时﹐您看前方多远的距离﹖",
      "option_list": [
        "4到5秒钟的行车时间",
        "6到8秒钟的行车时间",
        "10到15秒钟的行车时间"
      ],
      "answer": "3"
    },
    {
      "sequence": 16,
      "title": "16. 有小孩上下学的有标志的学校区﹐除非标明的限速更低﹐您驾车的速度不得高于____。",
      "option_list": [
        "时速15英里",
        "时速20英里",
        "时速25英里"
      ],
      "answer": "3"
    },
    {
      "sequence": 17,
      "title": "17. 如果您在驾车时昏昏欲睡﹐最好﹕",
      "option_list": [
        "加速以远离其它车辆",
        "将车开到一个安全的地方﹐停车休息",
        "通过经常变换车道来帮助您保持清醒"
      ],
      "answer": "2"
    },
    {
      "sequence": 18,
      "title": "18. 当机械信号装置闪烁的灯正在铁路交叉路口处运作﹐您必需﹕",
      "option_list": [
        "在机械讯号装置后十英尺处停车",
        "停车﹐然后在安全时继续行驶",
        "在穿过铁路交叉路口前要减速"
      ],
      "answer": "2"
    },
    {
      "sequence": 19,
      "title": "19. 在准备转弯前﹐为了避免被另一辆车从后面撞上﹐您应该﹕",
      "option_list": [
        "在任何时候都打开您的泊车灯",
        "在转弯前至少100英尺处发出讯号",
        "在转弯前至少200英尺处打开您的紧急讯号灯"
      ],
      "answer": "2"
    },
    {
      "sequence": 20,
      "title": "20. 在没有标志标出其它限速的情况下﹐在商业区驾车的限速是﹕",
      "option_list": [
        "时速20英里",
        "时速25英里",
        "时速30英里",
        "时速35英里"
      ],
      "answer": "2"
    },
    {
      "sequence": 21,
      "title": "21. 在交叉路口处闪烁的红色交通号灯意味您必需﹕",
      "option_list": [
        "在进入前要减速",
        "在进入前要停车﹐然后在安全的情况下继续行驶",
        "要等待绿灯亮"
      ],
      "answer": "2"
    },
    {
      "sequence": 22,
      "title": "22. 如果您的车有方向盘锁定装置﹐当车仍在移动时﹐您是否可在任何情况下关掉点火器﹖",
      "option_list": [
        "否﹐方向盘可能被琐住﹐然后您可能失去对车的控制",
        "否﹐除非您想在下坡时滑行",
        "是﹐但是当您急踩煞车踏板时﹐要对车打滑有所准备"
      ],
      "answer": "1"
    },
    {
      "sequence": 23,
      "title": "23. 当您跟车距离太近而另一辆车突然插到您面前时﹐您最好怎样做﹖",
      "option_list": [
        "急踩煞车踏板",
        "猛然拐入您侧边的车道",
        "把您的脚移离油门踏板"
      ],
      "answer": "3"
    },
    {
      "sequence": 24,
      "title": "24. 在您那边的道路的线是以下哪种时﹐您可以越过道路上的双线超车﹖",
      "option_list": [
        "虚线",
        "黄色实线",
        "黄色虚线"
      ],
      "answer": "1"
    },
    {
      "sequence": 25,
      "title": "25. 在经过车祸现场时减速“看一看”的人﹕",
      "option_list": [
        "是确定在现场有紧急救助人员",
        "使交通阻塞更严重﹐并且可能造成另一次车祸",
        "通过降低速度来帮助急救人员"
      ],
      "answer": "2"
    },
    {
      "sequence": 26,
      "title": "26. 搭您车的乘客﹐如果已满16岁或以上﹐是否会因不系上他或她的座椅安全带而得到一张罚单﹖",
      "option_list": [
        "是",
        "是﹐但只有坐在车的前座时",
        "否﹐因为座椅安全带在减少伤亡方面无效"
      ],
      "answer": "1"
    },
    {
      "sequence": 27,
      "title": "27. 在下列情况下﹐可在轻型卡车后面载人﹕",
      "option_list": [
        "如果该轻型卡车装有座椅系统而且坐在后面的人使用座椅系统的安全带",
        "如果该轻型卡车侧围的高度至少为24英寸",
        "如果该轻型卡车有固定在车架上的座椅"
      ],
      "answer": "1"
    },
    {
      "sequence": 28,
      "title": "28. 您的驾驶执照上注明一项条件“必需戴矫正性镜片”。您在前一天丢失了您的眼镜。应该么办﹖",
      "option_list": [
        "在您获得另一副眼镜前仅仅在您熟悉的道路上行驶",
        "在您获得另一副眼镜前不要驾驶",
        "仅在紧急情况下驾车"
      ],
      "answer": "2"
    },
    {
      "sequence": 29,
      "title": "29. 如果您停放的车自行滑动撞到另一辆车﹕",
      "option_list": [
        "您只需要向您的保险公司报告",
        "您必需在该车上留下一张字条﹐并且向当地执法机构或CHP书面报告",
        "您应该安排将该车辆拖到修车行修理"
      ],
      "answer": "2"
    },
    {
      "sequence": 30,
      "title": "30. 下述路缘颜色标记的含义中哪一项是正确的﹖",
      "option_list": [
        "蓝色-供没有标牌的残障人士泊车",
        "黄色-仅供装卸货物或上落乘客时泊车用",
        "红色-在有限时间内泊车﹐但要给公共汽车让位"
      ],
      "answer": "2"
    },
    {
      "sequence": 31,
      "title": "31. 停下的学校公共汽车上闪烁的红灯意思是﹕",
      "option_list": [
        "首先停车﹐然后在安全的情况下继续行驶",
        "在红灯闪烁期间要一直将车停车",
        "只有在您看到街上有小孩时才停车"
      ],
      "answer": "2"
    },
    {
      "sequence": 32,
      "title": "32. 当你在城市附近速限很低的地方驾车时是否要繫上安全带/座椅安全带?",
      "option_list": [
        "是-但只有在车速超过时速40英里时",
        "是",
        "否-在低速行驶时安全带没有效果"
      ],
      "answer": "2"
    },
    {
      "sequence": 33,
      "title": "33. 驾车时向前看的意思是您应该 :",
      "option_list": [
        "要观察您视力所能看到的前方的情况",
        "要观察您跟随著的前面一辆车的情况",
        "要观察所有的情况"
      ],
      "answer": "3"
    },
    {
      "sequence": 34,
      "title": "34. 在没有标志标出其他限速的情况下, 在住宅区驾车的限速是:",
      "option_list": [
        "时速30英里",
        "时速25英里",
        "时速20英里"
      ],
      "answer": "2"
    },
    {
      "sequence": 35,
      "title": "35. 在上下班交通“高峰期”堵塞交叉路口是:",
      "option_list": [
        "允许, 因为您有先行权",
        "如果您已经等过两次红绿灯允许",
        "绝对不允许"
      ],
      "answer": "3"
    },
    {
      "sequence": 36,
      "title": "36. 您不可以越过道路中央的双实线来",
      "option_list": [
        "您不想因任何原因越过双实线",
        "向左转",
        "在双向行驶的道路上超过另一辆车"
      ],
      "answer": "3"
    },
    {
      "sequence": 37,
      "title": "37. 在没有行人横道线的交叉路口处谁有先行权?",
      "option_list": [
        "车辆有先行权, 但应该减速并谨慎继续驾驭",
        "行人有先行权",
        "行人有先行权, 但只有在画出行人横道线的情况下"
      ],
      "answer": "2"
    },
    {
      "sequence": 38,
      "title": "38. 当您听到警笛声并且看到一辆有闪烁的红色紧急讯号灯的救急车辆离您很近地行驶来, 您必须:",
      "option_list": [
        "将车开到路的右侧边缘并停下",
        "将车驶入右边车道并缓慢行驶, 直到该车经过",
        "加速行驶使交通通畅"
      ],
      "answer": "1"
    },
    {
      "sequence": 39,
      "title": "39. 如果您屡次被判犯了交通违规:",
      "option_list": [
        "DMV将吊扣您车辆的登记证",
        "您的驾驶权利可能会被DMV吊销",
        "您只会被罚款"
      ],
      "answer": "2"
    },
    {
      "sequence": 40,
      "title": "40. 如果在您前面的驾驶者在行人横道线前停车, 您应该:",
      "option_list": [
        "轻按您的喇叭, 让该驾驶员知道您在等他",
        "停车, 然后在安全的情况下继续行驶",
        "~将车开到路的右侧边缘并停下"
      ],
      "answer": "2"
    },
    {
      "sequence": 41,
      "title": "41. 载客用车辆在从泊车位倒车出来时,您应该:",
      "option_list": [
        "依赖您的后向反光镜",
        "依赖您的左侧反光镜",
        "依您的右肩往后看"
      ],
      "answer": "3"
    },
    {
      "sequence": 42,
      "title": "42. 您在自行车旁边行驶, 并且想在转角处向右转, 您应该发出讯号, 仔细观察看是否有骑自行车者, 然后:",
      "option_list": [
        "在转弯前200英尺内汇入自行车道",
        "转弯, 但要小心避免进入自行车道",
        "加速, 在骑车者到达转角处前超过他们"
      ],
      "answer": "1"
    },
    {
      "sequence": 43,
      "title": "43. 如果您在驾车时昏昏欲睡, 最好:",
      "option_list": [
        "喝咖啡以增强您的警觉性",
        "将收音机的音量调到很大",
        "将车开到一个安全的地方, 停车休息"
      ],
      "answer": "3"
    },
    {
      "sequence": 44,
      "title": "44. 在销售或转让您的车辆后的多少天之内您必须向DMV报告?",
      "option_list": [
        "5天",
        "10天",
        "15天"
      ],
      "answer": "1"
    },
    {
      "sequence": 45,
      "title": "45. 您在何时可以合法地绕过放下的铁路交叉路口栏杆, 或从其边上绕过?",
      "option_list": [
        "当火车在离铁路交叉口有一段安全距离处停下",
        "如果铁路交叉口栏杆被卡住",
        "在任何时候均不可以"
      ],
      "answer": "3"
    },
    {
      "sequence": 46,
      "title": "46. 当您不能肯定是否应该打开前灯时, 您可以遵循的一个好习惯是:",
      "option_list": [
        "每当您有困难看到其他车辆的时候就要打开前灯",
        "如果会给其他驾驶者造成骚扰就不要打开前灯",
        "在日落后一个小时打开前灯"
      ],
      "answer": "1"
    },
    {
      "sequence": 47,
      "title": "47. 在一条单行街道上, 您通常可以从以下车道开始左转进入另一条单行街道:",
      "option_list": [
        "距左边路缘最近的车道",
        "左边或右边车道都可以",
        "距右边路缘最近的车道"
      ],
      "answer": "1"
    },
    {
      "sequence": 48,
      "title": "48. 当来到有停车标志的转角处, 您首先必须停车:",
      "option_list": [
        "在穿过行人横道线后",
        "在足以看到两边来往交通的地方",
        "在行人横道线前"
      ],
      "answer": "3"
    },
    {
      "sequence": 49,
      "title": "49. 以下那一位不被认为是行人?",
      "option_list": [
        "使用引路狗的人",
        "驾驶机动四轮车的残障人士",
        "骑机动自行车的人"
      ],
      "answer": "3"
    },
    {
      "sequence": 50,
      "title": "50. 涂上红色的路缘意味停车或泊车是:",
      "option_list": [
        "不允许的。(可能有标志允许公共汽车停车)",
        "仅供救急车辆用",
        "仅供乘客下车用"
      ],
      "answer": "1"
    },
    {
      "sequence": 51,
      "title": "51. 在以下情况下路面最滑:",
      "option_list": [
        "在暴风雨期间",
        "在下小雨的时候",
        "在长期干旱后开始下雨的时候"
      ],
      "answer": "3"
    },
    {
      "sequence": 52,
      "title": "52. 以下那一项对改善交通顺畅最有利?",
      "option_list": [
        "公共交通",
        "取消缓冲距离",
        "取消不必要的变换车道"
      ],
      "answer": "1"
    },
    {
      "sequence": 53,
      "title": "53. 在准备转弯前, 为避免被另一辆车从后面撞上, 您应该:",
      "option_list": [
        "在任何时候都打开您的泊车灯",
        "在转弯前至少100英尺处发出讯号",
        "经常通过您后视镜观察后面情况"
      ],
      "answer": "2"
    },
    {
      "sequence": 54,
      "title": "54. 在以下地点泊车是绝对不允许:",
      "option_list": [
        "在坡道上",
        "在残疾人停车位旁边的, 漆有白色交叉线的区域",
        "距铁轨二十英尺处"
      ],
      "answer": "2"
    },
    {
      "sequence": 55,
      "title": "55. 当您驶近”盲”交叉路口 (您无法看到交叉街道两边100英尺内的情况), 而且转弯处无STOP或YIRLD标志, 行车限速是:",
      "option_list": [
        "时速10英里",
        "时速15英里",
        "时速20英里"
      ],
      "answer": "2"
    },
    {
      "sequence": 56,
      "title": "56. 当您经过一个学校, 看到街道附近有小孩。除非标有更低的限速, 您的行车速度不得超过:",
      "option_list": [
        "时速20英里",
        "时速25英里",
        "时速30英里"
      ],
      "answer": "2"
    },
    {
      "sequence": 57,
      "title": "57. 在加州所有致命车祸中, 涉及饮酒驾驶者的大约占_____°",
      "option_list": [
        "40%",
        "60%",
        "85%"
      ],
      "answer": "2"
    },
    {
      "sequence": 58,
      "title": "58. 在销售或转让您的车辆后的多少天之内, 您必须向DMV报告?",
      "option_list": [
        "5天",
        "10天",
        "15天"
      ],
      "answer": "1"
    },
    {
      "sequence": 59,
      "title": "59. 当您在高速公路上汇道时, 您行车速度应该是 :",
      "option_list": [
        "大约与高速公路交通限速一致",
        "比高速公路限速大约低10英里",
        "比高速公路限速大约高5英里"
      ],
      "answer": "1"
    },
    {
      "sequence": 60,
      "title": "60. 盲交叉路口是在该交叉路口处您无法:",
      "option_list": [
        "在穿过前的最后100英尺内看到左右两边50英尺距离",
        "在穿过前的最后100英尺内看到左右两边100英尺距离",
        "在穿过前的最后100英尺内看到左右两边150英尺距离"
      ],
      "answer": "2"
    },
    {
      "sequence": 61,
      "title": "61. 在驾车长途旅行时, 为保持您的警觉性以及保持清醒, 除将车驶离路面休息外,您还应该:",
      "option_list": [
        "让车内吹进一些新鲜空气",
        "经常变换车道",
        "以低于标明的限速的速度行驶"
      ],
      "answer": "1"
    },
    {
      "sequence": 62,
      "title": "62. 如果您按照以下方式观察道路情况, 就会更容易发现前方的危险:",
      "option_list": [
        "将目光集中在车内的反光镜上",
        "将目光集中在前方的车辆",
        "对您车辆前方的情况进行扫视"
      ],
      "answer": "3"
    },
    {
      "sequence": 63,
      "title": "63. 涂上白色的路线意味在以下允许停车或泊车:",
      "option_list": [
        "如果装卸货车或上落乘客",
        "如果是在公共汽车上落乘客区",
        "如果是救急车辆或警车",
        "只允许足够的时间接送乘客或取送邮件"
      ],
      "answer": "4"
    },
    {
      "sequence": 64,
      "title": "64. 在以下情况下您行车速度可以超过时速65英里",
      "option_list": [
        "绝对不可以",
        "为跟其他车辆同时保持一致的速度",
        "在标有更高限速的高速公路上"
      ],
      "answer": "3"
    },
    {
      "sequence": 65,
      "title": "65. 加州的”基本速度法例”规定:",
      "option_list": [
        "您必须考虑道路、交通、以及天气情况",
        "您必须留意同步停车讯号灯",
        "您在受管制的交叉路口可能不得不突然减速"
      ],
      "answer": "1"
    },
    {
      "sequence": 66,
      "title": "66. 您是否在所有可能的情况下均要在您的车辆週围保持”缓衝距离”?",
      "option_list": [
        "是, 这样在其他车辆驾驶者犯错误的时候您就有时间做出反应",
        "是, 但只要在城区街道上",
        "否, 缓衝距离没有必要-他们佔据高速公路上宝贵的空间"
      ],
      "answer": "1"
    },
    {
      "sequence": 67,
      "title": "67. 您想在交叉路口作U形迴转。有两条左转车道。您在U形迴转时应该用两条车道的那一条?",
      "option_list": [
        "距街道中央最近的左转车道",
        "距路缘最近的车道",
        "在您的这一边道路的任何一条通行无阻的车道"
      ],
      "answer": "1"
    },
    {
      "sequence": 68,
      "title": "68. 您在什么时候应该放弃您的法定先行权?",
      "option_list": [
        "当这样做将有助于防止车辆事故时",
        "当别的驾驶者想要以比您更快的速度行驶时",
        "绝对不要, 这会迷惑其他驾驶者"
      ],
      "answer": "1"
    },
    {
      "sequence": 69,
      "title": "69. 在您的车中放置已开启的酒精饮料容器是违法的,除非:",
      "option_list": [
        "在车后备箱中",
        "在车的后座",
        "在手套箱内"
      ],
      "answer": "1"
    },
    {
      "sequence": 70,
      "title": "70. 如果您在高速公路见到橘黄色施工标志以及锥形标志, 您必须",
      "option_list": [
        "要小心道路工人以及慢行的设备",
        "变换车道, 同时保持车速",
        "将您的车速降至时速15英里或以下"
      ],
      "answer": "1"
    },
    {
      "sequence": 71,
      "title": "71. 在转角处穿过街道的行人在以下情况下有先行权:",
      "option_list": [
        "只有在行人交通管制装置运作正常时",
        "无论街道上是否画出行人横道线",
        "在管制车辆交通的讯号灯失灵时"
      ],
      "answer": "2"
    },
    {
      "sequence": 72,
      "title": "72. 如果一辆移动的车辆的左转弯与右转弯讯号同时闪烁。这一讯号的意思是:",
      "option_list": [
        "该车有先行权",
        "前方可能有危险或交通意外",
        "该车比其他车辆行驶速度更快"
      ],
      "answer": "2"
    },
    {
      "sequence": 73,
      "title": "73. 您在自行车辆旁边行政院驶,并且想在转弯处向右转。您发出讯号, 仔细观察看是否有骑自行车者, 然后:",
      "option_list": [
        "在转弯前200英尺内汇入自行车道",
        "转弯, 但要小心避免进入自行车道",
        "在转弯前50英尺内汇入自行车道"
      ],
      "answer": "1"
    },
    {
      "sequence": 74,
      "title": "74. 您在一条弯曲的双车道公路上驾驶一辆慢行车辆。当有以下数量的车辆跟随您时, 要在安全的时候将车驶到路边停下, 让其他车辆超过您:",
      "option_list": [
        "4辆车",
        "5辆车或更多的车",
        "无论有多少辆车您都无需将车驶到路边停下"
      ],
      "answer": "2"
    },
    {
      "sequence": 75,
      "title": "75. 骑自行车者必须:",
      "option_list": [
        "在被汽车超过时必须得到安全的空间",
        "一定不要推车穿过街道",
        "如果没有自行车道就要在行人道上骑车"
      ],
      "answer": "1"
    },
    {
      "sequence": 76,
      "title": "76. 当您遇到一辆停下来并且有红灯闪烁的学校公共汽车时, 如果您没有停车:",
      "option_list": [
        "您可能只被罚款",
        "您可能只被吊销驾驶执照",
        "您可能被最高罚款$1000, 并且被吊销驾驶权利"
      ],
      "answer": "3"
    },
    {
      "sequence": 77,
      "title": "77. 在从有角度的泊车位倒车出来时, 您应该:",
      "option_list": [
        "在慢速倒车出来时观察您的后向反光镜",
        "在慢速倒车出来时观察您的左侧反光镜",
        "从您的右肩向后看, 同时慢速倒车出来"
      ],
      "answer": "3"
    },
    {
      "sequence": 78,
      "title": "78. 除非为安全考虑必须这样做外, 行驶速度太慢, 以至于影响正常合理的交通流动, 这是:",
      "option_list": [
        "违法的",
        "驾驶者的权利",
        "合法, 但建议您不要这样做"
      ],
      "answer": "1"
    },
    {
      "sequence": 79,
      "title": "79. 阻塞交叉路口是:",
      "option_list": [
        "只要两边交通有些能够绕过您行驶就允许",
        "如果您遇到绿灯或黄灯时进入交叉路口, 并且不能在讯号灯变红前驶出交叉路口, 就允许",
        "绝对不允许"
      ],
      "answer": "3"
    },
    {
      "sequence": 80,
      "title": "80. 您经过一个学校, 看到街道附近有小孩, 除非标有更低的限速, 您的行车速度不得超过:",
      "option_list": [
        "时速20英里",
        "时速25英里",
        "时速30英里"
      ],
      "answer": "2"
    },
    {
      "sequence": 81,
      "title": "81. 在您离开您的车道以超过另一辆车时, 在以下情况下您就知道您有足够的空间进入您原来的行车道:",
      "option_list": [
        "可从您的后向反光镜看到您刚刚超过的车辆的前端",
        "超出另一辆车有三辆车车身的距离",
        "在另一辆车的前方有两秒的行车距离"
      ],
      "answer": "1"
    },
    {
      "sequence": 82,
      "title": "82. 以下那一项不是安全的驾车行为:",
      "option_list": [
        "在坏天气下打开雾灯",
        "凝视您车前方的路段",
        "在转动方向盘之前转头观察情况"
      ],
      "answer": "2"
    },
    {
      "sequence": 83,
      "title": "83. 在您的驾驶执照上注有一项条件“可在日出与日落之间驾车”。现在时间是晚上9:30, 您想去商店买治疗咳嗽的药。您应该怎么办?",
      "option_list": [
        "驾车去商店, 但车速不要超过时速15英里",
        "请一位朋友驾车送您去商店",
        "再等一小时, 这样路上交通会更稀少"
      ],
      "answer": "2"
    },
    {
      "sequence": 84,
      "title": "84. 在车流中变换车道之前, 您需要:",
      "option_list": [
        "从您的肩头向后看, 以确保您想进入的车道是通行无阻的",
        "发出讯号, 这样您就会有先行权",
        "加速超过其他所有车辆"
      ],
      "answer": "1"
    },
    {
      "sequence": 85,
      "title": "85. 在任何使您驾车不安全的麻药的影响下驾车是:",
      "option_list": [
        "如果有医生开的处方, 就允许",
        "不允许的",
        "如果是减肥丸或治疗感冒的药就允许"
      ],
      "answer": "2"
    },
    {
      "sequence": 86,
      "title": "86. 就大型卡车及公共汽车的盲点而言, 载客车辆通常应遵循的规则是什麽?",
      "option_list": [
        "如果您看不见该车的反光镜, 卡车司机亦无法知道您的存在",
        "如果该车有八个反光镜, 就不会有盲点",
        "如果您跟车距离不少于六英尺, 您就不在盲点中"
      ],
      "answer": "1"
    },
    {
      "sequence": 87,
      "title": "87. 您是否应该常常观察后面的交通情况?",
      "option_list": [
        "是, 这样您将知道是否有一位尾随者跟着您",
        "否, 您应该注意观察您前方的车辆",
        "否, 除非交通很拥挤"
      ],
      "answer": "1"
    },
    {
      "sequence": 88,
      "title": "88. 除非为了安全考虑必须这样做外, 行驶速度太慢, 以至于影响正常合理的交通流动, 这是:",
      "option_list": [
        "违法的",
        "驾驶者的权利",
        "比超速行驶更安全"
      ],
      "answer": "1"
    },
    {
      "sequence": 89,
      "title": "89. 在您的行车方向有三条车道供车辆行驶。通常交通最流畅的车道是:",
      "option_list": [
        "左车道(#1车道)",
        "中间车道(#2车道)",
        "右车道(#3车道)"
      ],
      "answer": "2"
    },
    {
      "sequence": 90,
      "title": "90. 您要注意看在供机动车辆行驶的车道上是否有骑自行车者,因为他们:",
      "option_list": [
        "必须面对迎面而来的交通骑车",
        "有权与您共用道路",
        "有先行权"
      ],
      "answer": "2"
    },
    {
      "sequence": 91,
      "title": "91. 黄线分隔的车道是:",
      "option_list": [
        "将单行街道上的车道分开",
        "将自行车道与普通车道分开",
        "将相反方向行走的交通车道分开"
      ],
      "answer": "3"
    },
    {
      "sequence": 92,
      "title": "92. 以下那一项不被认为是变换车道?",
      "option_list": [
        "从只能左转的车道向左转",
        "从进入车道进入高速公路",
        "离开路线进入车流"
      ],
      "answer": "1"
    },
    {
      "sequence": 93,
      "title": "93. 在您变换车道或向左转时每次一定要观察是否有摩托车, 因为:",
      "option_list": [
        "他们因体积小而难以被看见",
        "他们经过交叉路口时行驶速度太快",
        "他们可能没有打开其前灯来增加其可见度"
      ],
      "answer": "1"
    },
    {
      "sequence": 94,
      "title": "94. 在大雾中行驶时, 您应该大大降低您通常的行车速度,并且打开:",
      "option_list": [
        "前灯的低灯",
        "前灯的高灯",
        "只用雾灯"
      ],
      "answer": "1"
    },
    {
      "sequence": 95,
      "title": "95. 多人共乘车车道由菱形标记◇表示。如果您属于以下情况, 就可以在多人共乘车车道上行驶:",
      "option_list": [
        "只有周末使用该车道",
        "车中载有标志上所标明的最低人数",
        "打算超过您前面一辆车"
      ],
      "answer": "2"
    },
    {
      "sequence": 96,
      "title": "96. 在未铺设路面的路肩行驶以从前方车辆的右侧超车是:",
      "option_list": [
        "不允许",
        "如果您向右转就允许",
        "如果前方车辆正在向左转就允许"
      ],
      "answer": "1"
    },
    {
      "sequence": 97,
      "title": "97. 如果一辆移动的车辆的左转弯与右转弯讯号同时闪烁。这一讯号的意思是:",
      "option_list": [
        "该车有先行权",
        "前方可能有危险或交通意外",
        "该车比其他车辆行驶速度更快"
      ],
      "answer": "2"
    },
    {
      "sequence": 98,
      "title": "98. 26.所有的车辆同时到达在四个(或更多)转角均有停车标志的交叉路口。您必须给以下的驾驶者让路:",
      "option_list": [
        "在您右边的",
        "在您左边的",
        "发出转弯灯的"
      ],
      "answer": "1"
    },
    {
      "sequence": 99,
      "title": "99. 在以下情况下, 您可在遇到红灯时向右转:",
      "option_list": [
        "只有在有“允许右转”标志时， 并且在您安全地继续行驶的情况下",
        "没有“允许右转”标志时， 在您可以安全地继续行驶的情况下",
        "只要您在转弯前减速并且观察两边情况就可以"
      ],
      "answer": "2"
    },
    {
      "sequence": 100,
      "title": "100. 有时, 您会需要在您与前方车辆之间保持”3秒”的缓衝距离。其中的一种情形就是当您:",
      "option_list": [
        "跟随一辆小型载客车辆时",
        "被一位追尾者紧跟时",
        "在任何行车情况下, 三秒行车距离都有足够的缓衝距离"
      ],
      "answer": "2"
    },
    {
      "sequence": 101,
      "title": "101. 在以下情况下, 交通意外更可能发生:",
      "option_list": [
        "当其中一辆车的行车速度高于或低于路上的其他车辆时",
        "在桥梁或高架桥上",
        "在多车道的高速公路上"
      ],
      "answer": "1"
    },
    {
      "sequence": 102,
      "title": "102. 如果您身陷车祸, 并且有人受伤,死亡或者所造成损失超过$500,您必须:",
      "option_list": [
        "只要向治安官员出示您的保险证明",
        "只有在有人受伤的情况下才停车并表现您的身份",
        "向DMV做出书面报告(SR1)"
      ],
      "answer": "3"
    },
    {
      "sequence": 103,
      "title": "103. 在下列情况下, 未满四岁或不足40磅重的小孩在行驶的车中, 必须被繫紧在安全座椅中:",
      "option_list": [
        "由家长决定是否要这样做",
        "在所有的时候",
        "只有在高速公路上行驶的时候"
      ],
      "answer": "2"
    },
    {
      "sequence": 104,
      "title": "104. 涂上黄色的路线意味在以下允许停车:",
      "option_list": [
        "仅供装卸货车或上下乘客",
        "供在公共汽车乘客上落区用",
        "供救急车辆或警车用"
      ],
      "answer": "1"
    },
    {
      "sequence": 105,
      "title": "105. 除因交通原因外, 如果因其他任何原因在公路上停车, 您应该在以下那个地方停车:",
      "option_list": [
        "在靠近中央分隔带的地方",
        "在您的车可从前方200英尺处被人看见的任何地方",
        "在离开路面的地方"
      ],
      "answer": "3"
    },
    {
      "sequence": 106,
      "title": "106. 在没有栏杆或管制装置的铁路交叉路口处, 如果您在距离交叉路口100英尺距离内无法看到两边铁轨400英尺内的情况,限速是:",
      "option_list": [
        "时速10英里",
        "时速15英里",
        "时速25英里"
      ],
      "answer": "2"
    },
    {
      "sequence": 107,
      "title": "107. 在以下情况下进入交叉路口是违法的:",
      "option_list": [
        "遇到闪烁的黄灯而您没有首先完全停车",
        "您进入的时候会阻碍两边的交通",
        "您想要进入的车道被阻塞"
      ],
      "answer": "2"
    },
    {
      "sequence": 108,
      "title": "108. 为避免让车在滑溜的道路上打滑, 建议您采用的方法之一是:",
      "option_list": [
        "跟随您前面车的车轮印",
        "在您驶近弯道与交叉路口时减速",
        "如果您开始打滑就要迅速停车"
      ],
      "answer": "2"
    },
    {
      "sequence": 109,
      "title": "109. 如果一辆移动的车辆的左转弯与右转弯讯号同时闪烁。这一讯号的意思是:",
      "option_list": [
        "该车正在运送上下学的小孩",
        "该车比其他车辆行驶速度更快",
        "前方可能有危险或交通意外"
      ],
      "answer": "3"
    },
    {
      "sequence": 110,
      "title": "110. 黄线分隔的车道是:",
      "option_list": [
        "将单行街道上的车道分开",
        "将自行车道与普通车道分开",
        "将相反方向行走的交通车道分开"
      ],
      "answer": "3"
    },
    {
      "sequence": 111,
      "title": "111. 除因交通原因外, 如果因其他任何原因在公路上停车, 您应该在以下那个地方停车:",
      "option_list": [
        "在靠近中央分隔带的地方",
        "在您的车可从前方200英尺处被人看见的任何地方",
        "在离开路面的地方"
      ],
      "answer": "3"
    },
    {
      "sequence": 112,
      "title": "112. 您不得”双重泊车”:",
      "option_list": [
        "除非您距右边路缘不超过六英尺",
        "在任何时候",
        "除非在送货时"
      ],
      "answer": "2"
    },
    {
      "sequence": 113,
      "title": "113. 让车道的目的是什么?",
      "option_list": [
        "让您进入该车道以便让其他车辆超过您",
        "让车辆在不影响其他车辆行驶的情况下向右转",
        "让您的发动机在开始下坡前降温"
      ],
      "answer": "1"
    },
    {
      "sequence": 114,
      "title": "114. 您车中膝带与肩带的组合:",
      "option_list": [
        "可能防止车祸的发生",
        "两个同时系上通常比仅使用腹带更安全",
        "比慢速行驶的情况下没有效果"
      ],
      "answer": "1"
    },
    {
      "sequence": 115,
      "title": "115. 如果您是第一个在车祸现场停车提供救助的人, 您应该在以下地点驶离路面:",
      "option_list": [
        "在您经过车祸现场后",
        "在您到达车祸现场前",
        "在车祸现场的旁边"
      ],
      "answer": "1"
    },
    {
      "sequence": 116,
      "title": "116. 如果您年满21岁或以上,困在受影响的情况下驾车, 并且拒绝接受化验以确定您血液的酒精浓度:",
      "option_list": [
        "您的驾车权利将被取消",
        "您将受到额外的罚款",
        "您将不会被允许保释"
      ],
      "answer": "1"
    },
    {
      "sequence": 117,
      "title": "117. 使用引路狗或携带白色引路棍的人必须:",
      "option_list": [
        "在所有时候被给予先行权",
        "仅仅在转角处被给予先行权",
        "被告知应何时穿过街道"
      ],
      "answer": "1"
    },
    {
      "sequence": 118,
      "title": "118. 造成大部分追尾撞车的原因是:",
      "option_list": [
        "跟随距离太近",
        "在交通中穿梭",
        "看您后向反光镜的次数太频繁"
      ],
      "answer": "1"
    },
    {
      "sequence": 119,
      "title": "119. 在等待向左转时, 在以下情况下您应该给对面行驶的交通让路:",
      "option_list": [
        "直到至少有两辆车经过",
        "要给您的距离近到足以构成危险的车让路",
        "要给距您至少有500英尺远的车辆让路"
      ],
      "answer": "2"
    },
    {
      "sequence": 120,
      "title": "120. 当您在有路缘的双向有坡度的下坡街道上泊车, 您车的前轮方向应该是:",
      "option_list": [
        "向左转, 离开路线",
        "与路缘平行",
        "向右转, 指向路线"
      ],
      "answer": "3"
    },
    {
      "sequence": 121,
      "title": "121. 当您到达有闪烁黄灯的转角处, 您必须:",
      "option_list": [
        "等到绿灯在继续行驶",
        "减速并小心穿过",
        "继续以同样的速度行驶"
      ],
      "answer": "2"
    },
    {
      "sequence": 122,
      "title": "122. 以下关于驾驶员的描述，正确的是",
      "option_list": [
        "总是遵守交通标志和信号灯",
        "总是用转向灯表示转弯的方向",
        "不要认为其他驾驶员会让您优先"
      ],
      "answer": "3"
    },
    {
      "sequence": 123,
      "title": "123. 必须为紧急车辆让行时",
      "option_list": [
        "将车尽量停靠在右侧边",
        "将车驶入右车道，缓慢行驶直到紧急车辆通过",
        "即使在交叉路口也要立刻停车"
      ],
      "answer": "1"
    },
    {
      "sequence": 124,
      "title": "124. 急转弯时，您应该使用制动降低车速",
      "option_list": [
        "只在驶入弯道前",
        "在驶出弯道的所有时间",
        "在驶入弯道后"
      ],
      "answer": "2"
    },
    {
      "sequence": 125,
      "title": "125. 下列那种情况您应该更加小心的驾驶车辆",
      "option_list": [
        "在学校，操场以及居民区行驶",
        "在高速公路超过大型卡车",
        "看到管制的事故现场"
      ],
      "answer": "1"
    },
    {
      "sequence": 126,
      "title": "126. 转弯时仔细查看是否有摩托车，原因是",
      "option_list": [
        "摩托车必须占有整个行车道",
        "摩托车在转弯处享有先行权",
        "摩托车体积较小，较难发现"
      ],
      "answer": "3"
    },
    {
      "sequence": 127,
      "title": "127. 在高速公路最右侧车道行驶时，您",
      "option_list": [
        "应在入口处小心汇入车辆",
        "必须比其他行驶车辆慢",
        "必须将先行权让给汇入车辆"
      ],
      "answer": "1"
    },
    {
      "sequence": 128,
      "title": "128. 以下何种情况您不能超车",
      "option_list": [
        "如果有其他车辆准备驶入或穿越该道路",
        "如果您正行驶在多车道上",
        "如果您的车道左侧有一条黄色虚线"
      ],
      "answer": "1"
    },
    {
      "sequence": 129,
      "title": "129. 在其他车辆的右后方行驶",
      "option_list": [
        "很危险，因为您可能正处于对方的视野盲点",
        "是良好的驾驶习惯",
        "是保持左侧应急空间的好方法"
      ],
      "answer": "1"
    },
    {
      "sequence": 130,
      "title": "130. 大型卡车最有可鞥在哪里速度失控引起危险",
      "option_list": [
        "长且弯度较缓的弯道",
        "开上长而陡的山坡",
        "开下长而陡的山坡"
      ],
      "answer": "2"
    },
    {
      "sequence": 131,
      "title": "131. 在天气炎热时开始下雨，路面最滑的时候是",
      "option_list": [
        "刚开始下雨的几分钟",
        "下雨的几小时后",
        "雨停了以后"
      ],
      "answer": "1"
    },
    {
      "sequence": 132,
      "title": "132. 遇到红灯时，您什么时候可以右转",
      "option_list": [
        "减速并确认路况后",
        "除非有其他规定，在停车后",
        "任何时候都不可以"
      ],
      "answer": "2"
    },
    {
      "sequence": 133,
      "title": "133. 橙色指示牌表明",
      "option_list": [
        "前方有学校",
        "前方有交通信号灯",
        "前方正在施工"
      ],
      "answer": "3"
    },
    {
      "sequence": 134,
      "title": "134. 如何正确使用转向灯",
      "option_list": [
        "不可同时使用转向灯和转向手势",
        "如果打转向灯想要换线，其他驾驶者必须让您汇入车流",
        "换线时使用转向灯是好习惯"
      ],
      "answer": "3"
    },
    {
      "sequence": 135,
      "title": "135. 您正在一条单行线行驶，许多车从路边超过您的车辆，您应该",
      "option_list": [
        "驶入避让区让其他车先行",
        "驶入右侧路肩继续行驶",
        "保持车速车道不限"
      ],
      "answer": "1"
    },
    {
      "sequence": 136,
      "title": "136. 以下哪种情况汇入车流最安全",
      "option_list": [
        "缓慢驶入，以便其他车辆能为您让行",
        "驶向路肩并等待空位",
        "等到空隙足够大时驶入车流"
      ],
      "answer": "3"
    },
    {
      "sequence": 137,
      "title": "137. 以下哪些儿童要求使用儿童座椅",
      "option_list": [
        "6岁体重60磅儿童",
        "5岁体重55磅儿童",
        "5岁体重65磅儿童"
      ],
      "answer": "2"
    },
    {
      "sequence": 138,
      "title": "138. 如果路面被大雨淋湿，您应该",
      "option_list": [
        "跟车距离遵循保持“2秒”原则",
        "跟车保持一车身的距离",
        "加大跟车的距离"
      ],
      "answer": "3"
    },
    {
      "sequence": 139,
      "title": "139. 关于脚踏车，你应该注意",
      "option_list": [
        "脚踏车必须面向迎面的车流行驶",
        "脚踏车可能在您的视野盲点",
        "脚踏车拥有先行权"
      ],
      "answer": "2"
    },
    {
      "sequence": 140,
      "title": "140. 天气寒冷潮湿时,什么地方最容易结冰",
      "option_list": [
        "靠近山顶的道路",
        "沥青路面",
        "桥梁和立交桥路面"
      ],
      "answer": "3"
    },
    {
      "sequence": 141,
      "title": "141. 您在限速为每小时65英里的道路上行驶,交通拥挤,车流速度为每小时35英里,您的最佳行驶速度是",
      "option_list": [
        "每小时25英里",
        "每小时35英里",
        "每小时40英里"
      ],
      "answer": "2"
    },
    {
      "sequence": 142,
      "title": "142. 以下关于驾驶与服用药物的说法那种是正确的",
      "option_list": [
        "多数治疗头疼和感冒的药会使人瞌睡",
        "药物和酒精可以同时使用",
        "处方药在任何时候服用都是安全的"
      ],
      "answer": "1"
    },
    {
      "sequence": 143,
      "title": "143. 您在斜坡停车时,前轮朝向路边",
      "option_list": [
        "车头朝下",
        "车头朝上",
        "与道路平行"
      ],
      "answer": "1"
    },
    {
      "sequence": 144,
      "title": "144. 如果没有人行横道或者停车线,你应该将车停在",
      "option_list": [
        "路口前",
        "刚过路口",
        "路口前二十英尺"
      ],
      "answer": "1"
    },
    {
      "sequence": 145,
      "title": "145. 如果您的车开始在水面飘滑,您应该",
      "option_list": [
        "保持车速,以便获得更好的牵引力",
        "用力踩刹车,以防止您的车辆打滑",
        "逐渐减速,不要踩刹车"
      ],
      "answer": "3"
    },
    {
      "sequence": 146,
      "title": "146. 加州驾驶员手册上列出的两种阻塞交通的驾驶行为是",
      "option_list": [
        "驾驶时东张西望和不必要的更换车道",
        "追尾和变换道路",
        "无经验驾驶和驾驶时的漫不经心"
      ],
      "answer": "1"
    },
    {
      "sequence": 147,
      "title": "147. 某位行人在「禁止行走」（Don’t Walk）信号灯闪烁后开始穿越街道。这名行人在街道中间时，你行车方向的交通信号灯变成绿色，您应该：",
      "option_list": [
        "部份驶入交叉路口，以使您获得先行权",
        "在您能够完全通过交叉路口之前，不要驶入交叉口",
        "继续驶入交叉路口，并等候交通畅通"
      ],
      "answer": "2"
    },
    {
      "sequence": 148,
      "title": "148. 看后方车流的最重要的三个时间是：",
      "option_list": [
        "倒车、急转弯或穿越交叉路口之前时",
        "倒车、变换车道或迅速减速之前时",
        "变换车道、穿越交叉路口或迅速减速之前时"
      ],
      "answer": "2"
    },
    {
      "sequence": 149,
      "title": "149. 你行进的方向有两个行车道。你正在左车道行驶，有许多车辆从右车道超车。如果紧随你的车辆想开快一点，你应该：",
      "option_list": [
        "继续在该车道行车，以免妨碍车流",
        "驶向左侧，让其他车辆通过",
        "在安全的时候并入右车道"
      ],
      "answer": "3"
    },
    {
      "sequence": 150,
      "title": "150. 交通灯上出现指向右方的红箭头，表示您：",
      "option_list": [
        "可以在减速和观察车流后，向右转",
        "在红箭头变成绿色之前，不能向右转",
        "完全停住后，再向右转"
      ],
      "answer": "2"
    },
    {
      "sequence": 151,
      "title": "151. 拐弯时，您应该持续使用信号灯，因为：",
      "option_list": [
        "在完成转弯动作前，关掉信号灯是违法的",
        "让其他驾驶者知道您准备拐弯",
        "完成拐弯动作之前，关掉信号灯总是不安全的"
      ],
      "answer": "2"
    },
    {
      "sequence": 152,
      "title": "152. 您与您左边的驾驶者同时到达交叉路口，应该",
      "option_list": [
        "你先走",
        "你左边的驾驶者先走",
        "谁先发出转弯讯号谁先走"
      ],
      "answer": "1"
    },
    {
      "sequence": 153,
      "title": "153. 您在车中载有打开盖子的酒精容器时驾车是否合法？",
      "option_list": [
        "否，一律不合法",
        "是，如果该容器在车后背箱内",
        "是，如果该容器在车后座"
      ],
      "answer": "2"
    },
    {
      "sequence": 154,
      "title": "154. 您正驶向一个十字路口，发现交通讯号灯出了故障。您应该怎麽办？",
      "option_list": [
        "停车，然后安全时继续行驶",
        "减速，如有必要就停车",
        "如果有其他车辆在场才停车"
      ],
      "answer": "1"
    },
    {
      "sequence": 155,
      "title": "155. 一辆大卡车在一条三车道公路的中间车道行驶。您打算超越大卡车。最好的超车方法是：",
      "option_list": [
        "从左侧快速超车，然后切入到大卡车前方",
        "从左侧缓慢超车，然后切入到大卡车前方",
        "从右侧很快超车，然后切入到大卡车前方"
      ],
      "answer": "1"
    },
    {
      "sequence": 156,
      "title": "156. 如果您的车将从后方遭到撞击，您应当：",
      "option_list": [
        "准备好在撞车后刹车",
        "踩住刹车不放",
        "挂上空档，关掉发动机"
      ],
      "answer": "1"
    },
    {
      "sequence": 157,
      "title": "157. 在夜间驾驶时：",
      "option_list": [
        "尽可能不要使用远光灯",
        "仅在没有照明的街道使用远光灯",
        "在合法与安全的任何情况下使用远光灯"
      ],
      "answer": "3"
    },
    {
      "sequence": 158,
      "title": "158. 有关死角的以下那一种说法是正确的？",
      "option_list": [
        "无论换左线还是右线都只需要向右边回头看",
        "换左线时向左边回头看，换右线时向右边回头看",
        "车外配备两面镜子的车辆没有死角"
      ],
      "answer": "2"
    },
    {
      "sequence": 159,
      "title": "159. 下列有关饮用酒精和驾驶的陈述中，哪一项是正确的？",
      "option_list": [
        "如果您酒后可以直线行走，就可以安全驾车",
        "如果您血液中酒精浓度在法律规定限定之下，不会影响驾驶",
        "酒精会影响安全驾驶所需的您的判断力和自制力"
      ],
      "answer": "3"
    },
    {
      "sequence": 160,
      "title": "160. 您从一个弯曲下坡的匝道驶离高速公路，你应当：",
      "option_list": [
        "在进入转弯处之前应将车速减至安全的速度",
        "将车速减至交通标志所显示的高速公路限速",
        "等您进入弯道之后开始刹车"
      ],
      "answer": "1"
    },
    {
      "sequence": 161,
      "title": "161. 你正在驾车，左面有迎面而来的车辆，右边停着一排车。此时您应该调整方向：",
      "option_list": [
        "使得您与迎面而来的车辆之间的距离小于您与停泊车辆之间的距离",
        "使得您与停泊车辆之间的距离小于您与迎面而来的车辆之间的距离",
        "在迎面而来的车和停泊的车之间居中行驶"
      ],
      "answer": "3"
    },
    {
      "sequence": 162,
      "title": "162. 你几乎要通过交叉路口时，才意识到应左拐而不是向前。现在，你应该如何做？",
      "option_list": [
        "停在交叉路口，等至安全时左拐",
        "继续行驶至下一个交叉路口，然后找到一条返回路线",
        "倒车，观察是否有其它辆转，然后左拐"
      ],
      "answer": "2"
    },
    {
      "sequence": 163,
      "title": "163. 这个信号的意思是：",
      "option_list": [
        "单行道车流靠右行驶",
        "单车道靠右行驶",
        "所有车辆必须在前方右转"
      ],
      "answer": "1"
    },
    {
      "sequence": 164,
      "title": "164. 这个标志的意思是：",
      "option_list": [
        "前方为车道汇合处",
        "前方公路分隔带结束（即中央绿化带变成双黄线）",
        "前方道路有障碍物"
      ],
      "answer": "2"
    },
    {
      "sequence": 165,
      "title": "165. 你在一条左转车道之上看到一个信号说：「左转在绿灯亮时让行。」这一信号的是什么意思？",
      "option_list": [
        "您只能在绿灯亮时左转",
        "您可在绿灯亮起并且安全时左转",
        "您必须等到信号灯全绿时左转"
      ],
      "answer": "2"
    },
    {
      "sequence": 166,
      "title": "166. 一个橘色信号显示「前方修筑路」意思是：",
      "option_list": [
        "前方修筑道路",
        "前方有一条车道关闭",
        "前方需要绕路"
      ],
      "answer": "1"
    },
    {
      "sequence": 167,
      "title": "167. 这个信号表示：",
      "option_list": [
        "除非看见对面有来车，否则不要超车",
        "除非超车时不会发生危险，否则不要超越",
        "禁止超越其他车辆，无论出于何种原因"
      ],
      "answer": "3"
    },
    {
      "sequence": 168,
      "title": "168. 这个标记表示：",
      "option_list": [
        "靠右侧驾驶",
        "右转车辆须让行",
        "慢行车辆应该移向右侧"
      ],
      "answer": "1"
    },
    {
      "sequence": 169,
      "title": "169. 这个标志的意思是：",
      "option_list": [
        "仅允许使用轮椅的人士停车",
        "允许无特殊停车牌的残障人士停车",
        "仅允许持有特殊停车牌的残障人士停车"
      ],
      "answer": "3"
    },
    {
      "sequence": 170,
      "title": "170. 转变车道之上有信号表示仅仅在左转箭头亮起时允许左转或掉头。信号的意思是：",
      "option_list": [
        "你必须左转或掉头",
        "任何绿灯亮起都允许任何左转或掉头",
        "左转箭头亮起时允许左转或掉头"
      ],
      "answer": "3"
    },
    {
      "sequence": 171,
      "title": "171. 这种形状的橘黄色标志表示：",
      "option_list": [
        "机动车有优先权",
        "慢行车辆",
        "前方交通有危险状况"
      ],
      "answer": "2"
    },
    {
      "sequence": 172,
      "title": "172. 你打算在一条私人车道上左转，该道路中间有一条车道，该车道外沿有一条实线，内沿有一条虚线。你：",
      "option_list": [
        "可转入该车道，然后并入正常车流",
        "必须使用该车道，然后并入正常车流",
        "在左转时不得转入该车道"
      ],
      "answer": "1"
    },
    {
      "sequence": 173,
      "title": "173. 一个黄色菱形信号显示一个左转箭头。这一信号的意思是：",
      "option_list": [
        "道路左转",
        "您不能左转",
        "只能在出现绿色箭头时左转"
      ],
      "answer": "1"
    },
    {
      "sequence": 174,
      "title": "174. 此标志告诉你前面有铁路",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/175__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/175__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/175__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/175__4.gif"
      ],
      "answer": "2"
    },
    {
      "sequence": 175,
      "title": "175. 此标志告诉你准备停车。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/176__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/176__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/176__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/176__4.gif"
      ],
      "answer": "3"
    },
    {
      "sequence": 176,
      "title": "176. 此标志的意思是「此处水淹」。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/177__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/177__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/177__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/177__4.gif"
      ],
      "answer": "3"
    },
    {
      "sequence": 177,
      "title": "177. 此标志告诉你要慢驶。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/178__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/178__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/178__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/178__4.gif"
      ],
      "answer": "1"
    },
    {
      "sequence": 178,
      "title": "178. 此标志告诉你要绕道行驶。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/179__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/179__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/179__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/179__4.gif"
      ],
      "answer": "2"
    },
    {
      "sequence": 179,
      "title": "179. 此标志提醒你前面必须停车。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/180__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/180__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/180__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/180__4.gif"
      ],
      "answer": "2"
    },
    {
      "sequence": 180,
      "title": "180. 在设有此标志的街道上，如果你驶车慢行，你必须让速度快车辆先行。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/181__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/181__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/181__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/181__4.gif"
      ],
      "answer": "2"
    },
    {
      "sequence": 181,
      "title": "181. 此标志告诉你前面道路有人施工。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/182__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/182__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/182__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/182__4.gif"
      ],
      "answer": "3"
    },
    {
      "sequence": 182,
      "title": "182. 此标志告诉你有多少铁轨横跨前方道路。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/183__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/183__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/183__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/183__4.gif"
      ],
      "answer": "3"
    },
    {
      "sequence": 183,
      "title": "183. 此标志告诉你此处不准作任何转弯。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/184__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/184__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/184__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/184__4.gif"
      ],
      "answer": "2"
    },
    {
      "sequence": 184,
      "title": "184. 此标志告诉你要留意学生在此横过街道。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/185__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/185__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/185__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/185__4.gif"
      ],
      "answer": "2"
    },
    {
      "sequence": 185,
      "title": "185. 此标志告诉你「此处有沙石落下」。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/186__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/186__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/186__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/186__4.gif"
      ],
      "answer": "3"
    },
    {
      "sequence": 186,
      "title": "186. 此标志告诉你在右边线道上的车辆必须在出口处驶离公路。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/187__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/187__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/187__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/187__4.gif"
      ],
      "answer": "2"
    },
    {
      "sequence": 187,
      "title": "187. 此标志提醒你前面有窄桥。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/188__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/188__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/188__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/188__4.gif"
      ],
      "answer": "1"
    },
    {
      "sequence": 188,
      "title": "188. 此标志告诉你慢行车辆应该怎样做。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/189__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/189__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/189__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/189__4.gif"
      ],
      "answer": "2"
    },
    {
      "sequence": 189,
      "title": "189. 此标志告诉你前面有超车线道。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/190__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/190__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/190__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/190__4.gif"
      ],
      "answer": "3"
    },
    {
      "sequence": 190,
      "title": "190. 此标志告诉你，当红灯亮时，车辆不准转弯。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/191__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/191__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/191__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/191__4.gif"
      ],
      "answer": "1"
    },
    {
      "sequence": 191,
      "title": "191. 此标志提醒你前面有十字路口。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/192__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/192__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/192__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/192__4.gif"
      ],
      "answer": "3"
    },
    {
      "sequence": 192,
      "title": "192. 此标志告诉你，车辆慢行，前面道路在施工中。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/193__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/193__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/193__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/193__4.gif"
      ],
      "answer": "4"
    },
    {
      "sequence": 193,
      "title": "193. 此标志提醒你前面道路关闭。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/194__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/194__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/194__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/194__4.gif"
      ],
      "answer": "3"
    },
    {
      "sequence": 194,
      "title": "194. 此标志告诉你前面有左转弯。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/195__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/195__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/195__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/195__4.gif"
      ],
      "answer": "1"
    },
    {
      "sequence": 195,
      "title": "195. 此标志提醒你前面有交通讯号。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/196__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/196__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/196__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/196__4.gif"
      ],
      "answer": "1"
    },
    {
      "sequence": 196,
      "title": "196. 此标志告诉你禁止在红灯时作任何转弯。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/197__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/197__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/197__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/197__4.gif"
      ],
      "answer": "1"
    },
    {
      "sequence": 197,
      "title": "197. 此标志告诉你要留意学生在此横过街道。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/198__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/198__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/198__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/198__4.gif"
      ],
      "answer": "2"
    },
    {
      "sequence": 198,
      "title": "198. 此标志告诉你此处不要作左或右转弯。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/199__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/199__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/199__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/199__4.gif"
      ],
      "answer": "1"
    },
    {
      "sequence": 199,
      "title": "199. 此标志提醒你前面道路施工。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/200__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/200__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/200__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/200__4.gif"
      ],
      "answer": "1"
    },
    {
      "sequence": 200,
      "title": "200. 此标志告诉你，如果你正在右线道上行驶，你必须向右转弯。",
      "option_list": [
        "https://www.chineseinla.com/images/quiz/traffic/201__1.gif",
        "https://www.chineseinla.com/images/quiz/traffic/201__2.gif",
        "https://www.chineseinla.com/images/quiz/traffic/201__3.gif",
        "https://www.chineseinla.com/images/quiz/traffic/201__4.gif"
      ],
      "answer": "3"
    }
  ]