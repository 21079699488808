import React from "react";
import "./Highschool.scss";
import SubHeader from "../../../../components/SubHeader/SubHeader";
import CollapseCard from "../../../../components/CollapseCard/CollapseCard";
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";
import LangContext from '../../../../context';

export function IHighschool() {
  const {highschool} = React.useContext(LangContext).locale.programs.istudents;
  return (
      <div className="ihighschool">
        <SubHeader title={highschool.header} content={highschool.headerDesc}/>
        <div className="ihighschool-desc">
            <div className="image">
                <img
                    src="/images/middleSchool3-700x400.jpg"
                    alt="program"/>
            </div>
            <div>
                {highschool.desc.map( (ele,i) => {
                  if (Array.isArray(ele)) 
                    return (
                      <ul key={i}>{ele.map( listEle => (<li key={listEle}>{listEle}</li>))}</ul>
                    )
                  else return <p key={i}>{ele}</p>
                })}
                <div className="ihighschool-button">
                    <Link to="/programs/international-students/international-application">
                        <Button variant="contained">
                          {highschool.btn}
                        </Button>   
                    </Link>          
                </div>
            </div>
        </div>
        <h5>{highschool.cardTitles}</h5>
        <div className="ihighschool-packages">
            <div>
              {highschool.collapseCards.slice(0,5).map( (ele, i) => (
                <CollapseCard key={i} title={ele.title} desc={ele.desc}/>
              ))}
            </div>
            <div>
              {highschool.collapseCards.slice(5).map( (ele, i) => (
                <CollapseCard key={i} title={ele.title} desc={ele.desc}/>
              ))}
            </div>
        </div>
      </div>
  );
}
