import React from "react";
import './CollapseCard.scss';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

export default function CollapseCard(props) {
  const [close, setClose] = React.useState(false);

  const handleClick = e => {
    setClose(!close);
  }

  return (
    <div className="collapse-card">
      <div className="collapse-card-title">
        <div className={`collapse-card-title-icon ${!close ? "" : "green"}`} onClick={handleClick}>
            {!close ? <AddIcon/> : <RemoveIcon/>}
        </div>
        <div onClick={handleClick}>{props.title}</div>
      </div>
      <div className={`collapse-card-desc ${!close ? "" : "open"}`}>
            {props.desc.map( (ele, i) => {
                if (Array.isArray(ele)) 
                return (
                    <ul key={i}>{ele.map( listEle => (<li key={listEle}>{listEle}</li>))}</ul>
                )
                else return <p key={i}>{ele}</p>
                })
            }
      </div>
    </div>
  )
}
