import React from "react";
import {Button, Card, Radio} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import "./DrivingTest.scss";
import {TEST_QUESTIONS} from "../../config/driving-test";
import LangContext from '../../context';

export default function DrivingTest(props) {
  const QUESTIONS = TEST_QUESTIONS.map( e => Object.assign(e, {selected:null}))
  const [page, setPage] = React.useState(1);
  const [questions, dispatch] = React.useReducer((questions, { type, value }) => {
    switch (type) {
      case "add":
        let temp = JSON.parse(JSON.stringify(questions))
        temp[(page-1)*10+value.index].selected = value.option_index
        return temp;
      default:
        return questions;
    }
  }, QUESTIONS);
  const {drivingTest} = React.useContext(LangContext).locale;

  const changeOptionBackground = (selected, curr, answer) => {
    if (isNaN(selected)) return ""
    else if (curr == answer) return "green"
    else if (curr == selected && selected != answer) return "red"
  }
  

  return (
    <div className="driving-test">
      <div className="handbook-wrapper">
        <Button variant="contained" color="primary" onClick={ e => window.open("https://flex-static.oss-cn-hongkong.aliyuncs.com/handbook-EN.pdf")}>{drivingTest.handbookEN}</Button>
        <Button variant="contained" color="primary" onClick={ e => window.open("https://flex-static.oss-cn-hongkong.aliyuncs.com/handbook-CN.pdf")}>{drivingTest.handbookCN}</Button>
      </div>
      { drivingTest.handbookEN == "英文驾驶手册" ? <div className="questions-wrapper">
        {questions.slice((page-1)*10, page*10).map( (e, index) => 
          <Card key={index} className="question-box">
            <h5>{e.title}</h5>
            {e.option_list.map( (option, option_index) => 
              <div key={option_index} className={changeOptionBackground(parseInt(e.selected), parseInt(option_index), parseInt(e.answer)-1)}>
                <Radio
                  onChange={ _ => dispatch({ type: "add", value: {index, option_index}})}
                  checked={option_index === parseInt(e.selected)}
                  disabled={e.selected != null && parseInt(e.selected) != option_index}
                />
                <span>
                  {option.indexOf("http") == -1 ? option : <img src={option}/>}
                </span>
              </div>
            )}
          </Card>
        )}
        <Pagination 
          count={Math.ceil(TEST_QUESTIONS.length/10)}
          color="primary"
          page={page}
          onChange={(_, val) => setPage(val)}/>
      </div> : null}
    </div>
  );
}