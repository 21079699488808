export const LANG = {
    zhCN: {
        title: "福可思教育",
        toggleLocale: "切换语言",
        signin: "登录",
        signup: "注册",
        signout: "退出",
        login: {
            username: "用户名",
            password: "密码",
            btn: "登录",
            hint: "忘记",
            nav: "密码"
        },
        register: {
            username: "用户名",
            password: "密码",
            btn: "注册",
            hint: "已经注册？",
            nav: "登录",
        },
        nav: [
            "主页",
            "关于我们",
            "项目介绍",
            "联系我们",
            "在线课程",
            "资料"
        ],
        menuAbout: [
            "教学形式"
        ],
        menuPrograms: [
            "国际学生",
            "#本科国际学生",
            "#初高中国际学生",
            "#国际学生申请",
            "本科预备项目",
            "小初学术项目",
            "私人订制辅导",
            "夏令营",
        ],
        menuResources: [
            "驾照考试"
        ],
        menuCourses: [
            "AAA车辆安全课程",
        ],
        main: {
            sections: [
                {
                    title: "国际学生",
                    desc: "为中学到大学各阶段的留学生提供学术辅导、升学规划以及全方位的人格培养",
                    btn: "了解更多"
                },
                {
                    title: "本科预备项目",
                    desc: "帮助学生获取本科学习所需要的素质和技能",
                    btn: "了解更多"
                },
                {
                    title: "小初学术项目",
                    desc: "针对小学-初中的学生设立的读书俱乐部、以及专项科目辅导",
                    btn: "了解更多"
                },
                {
                    title: "私人订制辅导",
                    desc: "不限年龄段，针对每个学生的个性采取差异化的学术辅导",
                    btn: "了解更多"
                },
            ],
            footer: { desc: "Flex的顾问和教师们致力于引导学生发掘自身的才能，引领学生克服一个又一个挑战" }
        },
        programs: {
            header: "特色项目",
            desc: "福克斯教育能为国际学生和本土学生提供认证的学术以及文化项目。无论你正打算赴美学习或是已经开始了留美生活，我们都可以为你提供合适的方案以满足你的学习目标",
            istudents: {
                general: {
                    header: "国际学生",
                    desc: [
                        "来美国学习激动人心且收益良多，但同样充满挑战和困惑。签证、申请、住宿、择校等……一个又一个麻烦事层出不穷。但依靠着扎根美国12年的本土经验，Flex教育可以加速并简化所有这些流程。Flex教育为国际学生提供了一套完整的“关怀包”，减轻他们在美国学习的压力和不适。无论你是初中生、高中生还是大学生，我们专业的国际学习顾问都能够帮助你全方位地适应、融入美国教育系统并自我成就。",
                        [
                            "国际学生代理",
                            "语言能力辅导",
                            "本科咨询顾问"
                        ]
                    ],
                    footer: [
                        "初高中学生",
                        "大学学生",
                    ]
                },
                highschool: {
                    header: "初高中国际学生",
                    desc: ["关怀包为你提供过渡到新学习环境所需要的一切，帮助你融入新的学校、新的环境以及新的文化，并将引导你学习、成长、发掘和实现自我",],
                    btn: "国际学生申请",
                    cardTitles: ["关怀礼包"],
                    collapseCards: [
                        {
                            title: "学校中介服务",
                            desc: ["Flex教育首先评估每个学生的技能水平、兴趣和个性，再根据我们在湾区长达12年与各种寄宿&走读学校合作的经验，将学生匹配到最合适的学校。一旦选定学校，我们将协助转介、申请、面试和文件处理。Flex教育将时刻与学校、学生和家庭保持着密切的联系，直到入读。"]
                        },
                        {
                            title: "政府证明文件",
                            desc: ["Flex教育协助学生完成政府表格填写以及学生签证申请。"]
                        },
                        {
                            title: "住宿及生活服务",
                            desc: ["留学生活不仅关乎学习，更关乎生活。无论是住校还是寄宿，我们在意学生的生活环境，为他们做精心的选择，帮助他们更快更好地适应生活。Flex教育还提供必要的医疗和健康支持，包括保险、体检，以及任何其他必需的生活支持，如银行账户。"]
                        },
                        {
                            title: "选课建议与追踪",
                            desc: ["大学申请的准备始于初高中课程的学习。作为学生的代理，Flex教育会帮助每个学生做整体规划，有的放矢地选择课程，并随时追踪进度以助学生顺利完成学习计划。"]
                        },
                        {
                            title: "学术辅导",
                            desc: ["对大多学生来说，美国和国内的学习方式有着很大的差异，有时需要一些额外的帮助才能获得满意的结果。Flex教育提供完整的、基于各学科的个性化辅导，帮助学生获得理想的成绩。"]
                        },
                        {
                            title: "暑期课程咨询",
                            desc: ["许多学生会选择参加当地大学的暑期课程。这些课程让学生能高中阶段提前完成本科各专业的核心课程，并且能让学生先人一步体验大学生活。除了普遍意义上的暑课之外，许多大学还提供特定领域的实习和夏令营项目。Flex教育就所有这些课程的性质、适合性和申请流程向学生提供建议。"]
                        },
                        {
                            title: "选校与申请",
                            desc: ["Flex教育仔细评估每个学生的学术能力、兴趣和潜力，与学生、老师和家长合作，确定最适合学生的院校和专业。Flex教育有超过200名学生被常春藤盟校、T1级私立或公立大学录取。我们有丰富的学校信息储备和名校申请经验来帮助学生找到最合适的学校，并完成申请过程的各个方面。从填写表格到个人陈述，我们提供专业的帮助和指导!"]
                        },
                        {
                            title: "SAT/ACT备考",
                            desc: ["Flex教育首先会帮助学生选择参加哪些考试，然后为学生在高三和高四阶段制定一个时间表。在之后，我们会为学生安排辅导，强化学科能力和应试策略。"]
                        },
                        {
                            title: "名校之旅",
                            desc: [
                                "2018年春天，Flex教育开始提供从波士顿到纽约的顶级大学之旅。这些参观活动规模很小，而且非常个性化，学生们可以直接看到美国一些最著名的大学的设施、课程和环境。我们提供交通、住宿、膳食、校园设施观光和申请讲座。",
                                [
                                    "哈佛大学",
                                    "塔夫茨大学",
                                    "东北大学",
                                    "波士顿学院",
                                    "布朗大学",
                                    "罗德岛设计学院",
                                    "耶鲁大学",
                                    "哥伦比亚大学",
                                    "纽约大学",
                                ]
                            ]
                        }
                    ]
                },
                college: {
                    header: "本科国际学生",
                    desc: [
                        "个性评估和选校推荐",
                        [
                            "Flex教育首先对学生的学校表现、兴趣和资质作出评估，并基于此为学生推荐适合的学校和专业。",
                            "定校后，我们会协助学生完成申请、个人陈述、推荐、成绩单提交、面试及相关文件处理等步骤。"
                        ],
                    ],
                    btn: "国际学生申请",
                    cardTitles: ["4年制本科", "社区大学转学（2+2）"],
                    collapseCards: [
                        {
                            title: "专业及课程建议",
                            desc: ["入学后的第一步便是专业选择和学习规划。着眼于学生未来的学习和职业目标，Flex教育会与学校顾问合作，帮助学生制定选课计划和时间表，确保学生走在属于自己的正轨上"]
                        },
                        {
                            title: "学科辅导",
                            desc: ["对大多学生来说，美国和国内的学习方式有着很大的差异，有时需要一些额外的帮助才能获得满意的结果。Flex教育提供完整的、基于学科的个性化辅导，帮助学生达成学业目标"]
                        },
                        {
                            title: "加州社区大学",
                            desc: ["加州拥有全美最广泛的两年制公立大学网络。这些学校一方面提供职业培训项目，另一方面更因它们的4年制转学项目（2+2）而广受欢迎"]
                        },
                        {
                            title: "2+2项目",
                            desc: [
                                "在2+2项目中，学生一般会在社区大学学习2年，然后转到4年制大学完成后2年的学习，本科学位由转入的大学颁发。社区大学提供的许多课程被加州大学(UC)、加州州立大学(CS)以及许多其他州立学校所接受，学生修这些课所得学分可以等比例地转到这些学校。",
                                "基于此，Flex教育会帮助学生:",
                                [
                                    "1. 选择并申请合适的社区大学和转学项目",
                                    "2. 选择符合专业核心课程和选修课程要求的课程",
                                ]
                            ]
                        },
                        {
                            title: "2+2项目的优势",
                            desc: [
                                "当学生:",
                                [
                                    "高中GPA不足以申入满意的大学",
                                    "英语能力需要加强",
                                    "目前本科学习因成绩差而中断，需要补考的时候",
                                ],
                                "社区大学2+2项目往往是比直接申4年制大学更好的选择。转学的学生往往能够去到比高中毕业直申更优秀的学校，因为通常来说，社区大学的班级更小且更注重基础课教学，学校的目的，就是为了让学生转到好的4年制大学。因此，社区大学的老师通常更用心，而学生的成绩也更好。",
                                "另外，由于4年制大学的学费往往几倍于社区大学，2+2项目能够为学生家庭省下不小的开销。",
                            ]
                        },
                        {
                            title: "我们能做什么？",
                            desc: [
                                "Flex教育和许多当地的社区大学保持着良好的关系，因为每年Flex都有在社区大学的学生作为优等生转到UCB、UCLA一类的知名院校。我们在专业选择、课程辅导和转学申请方面都能给到学生最有力的支持。",
                            ]
                        }
                    ]
                },
                application: {
                    header: "国际学生申请",
                    studentForm: {
                        title: "学生信息",
                        submitter: "申请者",
                        submitOptions: [
                            { value: 0, text: "学生" },
                            { value: 1, text: "家长" },
                        ],
                        studentName: "学生姓名",
                        parentName: "家长姓名",
                        address1: "地址1",
                        address2: "地址2",
                        mailEmail: "主要邮箱",
                        altEmail: "备用邮箱",
                        city: "城市",
                        phone: "固定电话",
                        state: "省份",
                        zip: "邮编",
                        parentPhone: "家长电话",
                        country: "国家",
                        countryOptions: [
                            { value: 0, text: "中国" },
                            { value: 1, text: "美国" },
                            { value: 2, text: "加拿大" },
                            { value: 3, text: "其他" }
                        ],
                        studentPhone: "学生电话",
                    },
                    status: {
                        title: "学生状态",
                        options: [
                            { value: 0, text: "美国本土学生，正在美国学习中" },
                            { value: 1, text: "国际生，计划前往美国学习" },
                            { value: 2, text: "国际生,目前已在美国开始学习" },
                        ]
                    },
                    statusForm: {
                        eduLevel: "计划开始的年级:",
                        eduOptions: [
                            { value: 0, text: "初中" },
                            { value: 1, text: "高中" },
                            { value: 2, text: "本科" },
                        ],
                        visaStatus: "当前visa签证状态",
                        visaOptions: [
                            { value: 0, text: "F1 - 已获得或将会获得" },
                            { value: 1, text: "B1 - 已获得或将会获得" },
                            { value: 2, text: "B2 - 已获得或将会获得" },
                            { value: 3, text: "在外美国公民" },
                            { value: 4, text: "没有visa签证" },
                        ],
                        visaExpiry: "visa过期时间",
                        beginYear: "计划开始学习年份",
                        semesterTitle: "计划入学学期",
                        semesterOptions: [
                            { value: 0, text: "秋季入学" },
                            { value: 1, text: "春季入学" }
                        ],
                        major: "本科专业"
                    },
                    educationForm: {
                        title: "学历信息",
                        subTitle1: "学历等级和GPA",
                        eduLevel: "当前学历",
                        eduOptions: [
                            { value: 0, text: "初中" },
                            { value: 1, text: "高中" },
                            { value: 2, text: "本科" },
                        ],
                        gradeLevel: "当前年级",
                        gradeOptions: [
                            { value: 0, text: "6年级" },
                            { value: 1, text: "7年级" },
                            { value: 2, text: "8年级" },
                            { value: 3, text: "9年级" },
                            { value: 4, text: "10年级" },
                            { value: 5, text: "11年级" },
                            { value: 6, text: "12年级" },
                            { value: 7, text: "13年级" },
                            { value: 8, text: "大一" },
                            { value: 9, text: "大二" },
                            { value: 10, text: "大三" },
                            { value: 11, text: "大四" },
                        ],
                        gpa: "当前GPA",
                        subTitle2: "学校信息",
                        school: "学校名称",
                        country: "国家",
                        countryOptions: [
                            { value: 0, text: "中国" },
                            { value: 1, text: "美国" },
                            { value: 2, text: "加拿大" },
                            { value: 3, text: "其他" }
                        ],
                        address1: "地址1",
                        address2: "地址2",
                        city: "城市",
                        state: "省份",
                        zip: "邮编",
                        interestTitle: "选择你感兴趣的辅导项目",
                        interestSubTitle: "选择一个或多个辅导项目*",
                        interestSubjects: [
                            [
                                "择校规划",
                                "学科辅导",
                                "其他"
                            ],
                            [
                                "本科关怀包",
                                "形象培养",
                                "法务咨询"
                            ]
                        ]
                    },
                    comment: {
                        title: "额外信息/留言",
                        placeHolder: "留下你想要我们了解的任何信息"
                    },
                }
            },
            precollege: {
                header: "本科预备项目",
                desc: [
                    "Flex教育的本科预备项目针对高中以及大一大二的学生设置，旨在提升学生的综合学习能力以更好地应对本科阶段的学习。我们能帮助学生：",
                    [
                        "减少困惑和压力",
                        "避免可能的学术违规",
                        "提高成绩，尤其是人文学科的成绩",
                        "增强自信和表达能力",
                        "增强学术研究能力",
                    ],
                ],
                cardTitles: ["最受欢迎的辅导项目："],
                collapseCards: [
                    {
                        title: "论文写作技巧",
                        desc: ["从5段到50页，从课题研究、信息调查到段落构架、语言组织，我们能教你如何写出优质的论文"]
                    },
                    {
                        title: "引用技巧",
                        desc: ["有时候缺失了一个引用或是引用了不当的内容会让你的整篇论文甚至整堂课fail掉。如果你不知道引用什么、怎么引用，那这部分内容对你来说有大用。",
                            "本课程将教会学生如何查找和辨别可信度高、内容一致且高质量的引用来源。"]
                    },
                    {
                        title: "分析性阅读和批判性思维能力",
                        desc: ["修辞分析，论据评估，假设，推理……我们会教学生如何深入地阅读，并且能够清晰、完整地给出反馈。"]
                    },
                    {
                        title: "校园生活中的自我管理",
                        desc: ["Flex教育的终极目标是提升学生广义上的自我管理能力，但是这个目标的第一步始于学校这个小社会。如何在这个环境中活的健康、活的高效、活的得意？如何去有效地学习、更好地表达、了解自己的需求、与各种团队合作、适应文化差异？这些都是我们会帮助学生去探索和解决的主题。"]
                    },
                    {
                        title: "美式英语口语流利",
                        desc: ["真相是无法回避的:我们都有口音!弹性教育帮助那些接触美国英语较少的国际学生提高语言清晰度和语言理解能力。"]
                    }
                ]
            },
            k8students: {
                header: "小初学生项目",
                desc: [
                    { title: "阅读俱乐部" },
                    "Flex教育希望培养学生终身阅读的习惯。我们的阅读俱乐部为各个年龄段的学生提供适合他们水平的读物，在轻松却有序的环境中引导他们不断提升阅读能力。",
                    { title: "辅导" },
                    "Flex教育由专业的老师提供一对一的学科辅导，帮助学生提升成绩。高年级的学生也会为低年级的学生提供帮助。",
                ]
            },
            tutoring: {
                header: "个性化辅导",
                cards: [
                    {
                        title: "因材施教",
                        desc: ["Flex教育的老师们有着不同的个性以及教学风格。对于每个学生，我们会选择最合适的老师监督他的学习生活以保证效果"]
                    },
                    {
                        title: "苟且or精彩",
                        desc: [
                            "对于很多学生而言，也许只靠自己也能够勉强应付学业，而个性化辅导却能够帮他们充分发掘自身的潜力。Flex的个性化辅导帮助学生:",
                            [
                                "巩固基本知识",
                                "准备考试和测验",
                                "完成作业和项目",
                                "提高成绩",
                                "完成申请",
                            ]
                        ]
                    },
                    {
                        title: "学生家长间的纽带",
                        desc: ["Flex的老师会与学生和家长作为一个团队一起评估学生的能力、规划学生的未来。"]
                    },
                    {
                        title: "面授或视频会议",
                        desc: ["辅导通常在我们当地的办公室进行，但对于年纪较大的学生，我们也提供在线辅导，尤其是当时间或距离不允许时。我们为在线视频会议提供一个免费、易用的应用程序，提供聊天、白板和屏幕共享功能，以增强学习体验。"]
                    }
                ]
            }
        },
        about: {
            header: "关于我们",
            cards: [
                {
                    type: "wide-card",
                    title: "规整化一的教育方针",
                    img: "/images/about_puzzle-400x300.jpg",
                    desc: ["自2007年以来，Flex教育已经为200多名学生提供了服务。有别于相互独立的“学科培训”或“留学中介”服务，Flex教育团队认为教育应该是帮助学生自我实现的更全面的过程。每个学生都是一个完整的人，有着不同的梦想、能力、家庭和性格，Flex教育通过个性化关注帮助学生提升学术能力和综合素质。"]
                },
                {
                    type: "tall-card",
                    reverse: true,
                    img: "/images/about_tutor-440x550.jpg",
                    title: "随时随地的个性化关注",
                    desc: ["无论是通过线下还是视频会议，Flex教育都会给予每个学生个性化的关注。我们的app提供视频会议、信息收发、屏幕共享、文档分享等功能，切实地做到随时随地互通有无"]
                },
                {
                    type: "wide-card",
                    title: "我们在哪儿",
                    img: "/images/SilValley-Sunrise-700x400.jpg",
                    desc: ["作为起点，我们把总部安置在旧金山湾和蒙特雷湾之间的圣克鲁兹山脉，能够很好地辐射两个地区的学术设施和户外资源。但我们并不止于加州，我们的学生遍布美国和加拿大各地，这些地方都是我们可及之处。"]
                }
            ]
        },
        contact: {
            header: "联系我们",
            title: "欢迎与我们交流！",
            name: "姓名(必填)",
            nameHolder: "姓名",
            email: "邮箱(必填)",
            emailHolder: "email@example.com",
            subject: "学科",
            message: "留言",
            info: "联系信息",
            btn: "提交",
            address: [
                "杭州市萧山区永久路288号万象汇B座1816室"
            ],
            phone: [
                "17376598077",
                "0571-83527397"
            ],
            time: "周一至周五, 9:00-17:00",
        },
        drivingTest: {
            handbookEN: "英文驾驶手册",
            handbookCN: "中文驾驶手册",
        },
        videos: {
            titles: [
                "如何防止车辆被盗",
                "常见的车辆知识误区",
                "如何正确的把握方向盘",
                "如何避免怒路症",
                "如何进行雪天驾驶",
                "如何养成正确的驾驶习惯来减少交通堵塞",
                "紧急情况的车辆避让",
                "被遗忘的车辆小知识",
                "常见的车辆驾驶问题",
                "如何正确的共享车道",
                "注意车内指示灯的警告",
                "如何应对当车辆发生碰撞",
                "如何应对当动物挡住车辆路线",
                "如何应对当车辆抛锚",
            ],
            listTitle: "视频列表"
        }
    },
    enUS: {
        title: "Flex Education",
        toggleLocale: "CHANGE LANGUAGE",
        signin: "LOGIN",
        signup: "REGISTER",
        signout: "SIGN OUT",
        login: {
            username: "username",
            password: "password",
            btn: "LOGIN",
            hint: "Forget",
            nav: "password"
        },
        register: {
            username: "username",
            password: "password",
            btn: "REGISTER",
            hint: "Already registered?",
            nav: "Login"
        },
        nav: [
            "HOME",
            "ABOUT",
            "PROGRAMS",
            "CONTACT",
            "ONLINE COURSES",
            "RESOURCES",
        ],
        menuAbout: [
            "Our Approach"
        ],
        menuPrograms: [
            "International Students",
            "#International College Students",
            "#International Middle&High School Students",
            "#International Student Application",
            "Pre-college Programs",
            "K-8 Students",
            "Individual Tutoring",
            "Summer Camps",
        ],
        menuResources: [
            "Driving Test"
        ],
        menuCourses: [
            "AAA Driver Safety Courses",
        ],
        main: {
            sections: [
                {
                    title: "INTERNATIONAL STUDENTS",
                    desc: "Exclusive programs for international students from middle school through to college.",
                    btn: "LEARN MORE",
                },
                {
                    title: "PRE–COLLEGE PROGRAMS",
                    desc: "Premier admissions advising and skill-building classes for college success.",
                    btn: "LEARN MORE",
                },
                {
                    title: "K–8 STUDENT PROGRAMS",
                    desc: "Tailored reading clubs and private subject tutoring for younger students.",
                    btn: "LEARN MORE",
                },
                {
                    title: "INDIVIDUAL TUTORING",
                    desc: "Personalized subject tutoring for all students from elementary to college levels.",
                    btn: "LEARN MORE",
                },
            ],
            footer:
            {
                title: "CHALLENGE. SUPPORT. LAUNCH!",
                desc: "With a team of dedicated advisors and educators, Flex Education provides the personalized support each student needs to reach his or her full academic potential."
            }
        },
        programs: {
            header: "Programs",
            headerDesc: "Proven Academic & Cultural Programs",
            desc: "Flex Education brings proven academic and cultural programs to international and local students. Whether you are thinking of studying in the US, or already here, we can help you to find your place and reach your learning potential!",
            istudents: {
                general: {
                    header: "International Students",
                    headerDesc: "Middle School, High School, and College Students – Flex Education means direction, support, and success!",
                    desc: [
                        "Studying in the US can be exciting and rewarding – it can also be challenging and sometimes confusing. Visas, applications, accomodations, school selection, etc, etc, etc….the list seems ever-ending, but with over 12 years of experience, Flex Education can speed up and simplify all of those important tasks.",
                        "Flex Education provides International Students a “Complete Care Package” that takes the stress and guesswork out of studying in the US. Whether you are a middle, high school, or college student, let our expert International Agency advisors assist you in all aspects of preparing for, entering into, and succeeding in the US education system",
                        [
                            "International student agents",
                            "ESL support",
                            "College advising"
                        ]
                    ],
                    footer: [
                        "MIDDLE & HIGH SCHOOL STUDENTS",
                        "COLLEGE STUDENTS",
                    ]
                },
                highschool: {
                    header: "International Middle & High School Students",
                    headerDesc: "Flex Education provides everything needed for a safe, nurturing, and supportive environment to become a strong and independent student.",
                    desc: [
                        "You are ready to spread your wings and take on new challenges, fully immersing yourself in a new school system, a new environment, and a new culture.",
                        "You will learn and grow and be guided to fulfill your potential and be all you can be!",
                        "Complete Care packages provide everything you need to transition smoothly to your new learning environment:",
                    ],
                    btn: "INTERNATIONAL STUDENT APPLICATION",
                    cardTitles: ["COMPLETE CARE PACKAGES"],
                    collapseCards: [
                        {
                            title: "Assessment, Referral and Placement",
                            desc: [
                                "Flex Education first assesses the individual student’s skill levels, interests, and personality. We then match the student with schools we know to be a good fit, based on over 12 years of experience with boarding and day schools in the San Francisco and Monterey Bay areas.",
                                "Once the school is selected, we assist with the referral, application, interview, and documentation process.",
                                "Flex Education is in constant contact with the school, the student, and the family until placement is confirmed!"
                            ]
                        },
                        {
                            title: "Documentation",
                            desc: ["Flex Education ensures that the student is ready to travel to and study in the US. We assess the travel documentation requirements and assist with the completion of government forms, including student visa applications."]
                        },
                        {
                            title: "Accomodations and Lifestyle Support",
                            desc: [
                                "An important part of the study-abroad experience is insuring that all student feel safe and welcomed in their new homes – whether they are living in a school dormitory or with a private host family. We choose each living situation carefully, to allow students to adapt more quickly and easily.",
                                "Flex Education also arranges required medical and wellness support including insurance, check-ups as well as any other in-country necessities such as bank accounts."
                            ]
                        },
                        {
                            title: "Course Advising and Tracking",
                            desc: ["The runway to college admissions begins in middle and high school studies that are structured to maximize each student’s potential. As the student’s agency, Flex Education will help each student choose courses wisely and then monitor the student’s progress to encourage successful completion of the study program."]
                        },
                        {
                            title: "Tutoring",
                            desc: ["Studying in the US is a very different experience for many students and sometimes a little extra help is needed to attain satisfactory results. Flex Education provides complete, subject-based tutoring services that individualize learning and enable students to reach their grade goals."]
                        },
                        {
                            title: "Summer College Course Counseling",
                            desc: [
                                "Many students choose to enroll in summer studies classes at local colleges. These courses give students a unique taste of college life and the opportunity to complete core courses for a variety of majors, while still in high school.",
                                "Additionally, many colleges offer special interest internships and camps. Flex Education advises students on the nature, suitability and application process on all of these programs."
                            ]
                        },
                        {
                            title: "College Advising and Application Assistance",
                            desc: ["This is the big one! After years of rigorous, carefully chosen studies, high school students are ready to find that perfect-match college! Flex Education diligently assesses each student’s academic abilities, interests and potential, and then works with high school staff, parents and students to identify the very best colleges and majors.",
                                "With over 200 student-clients that have been accepted to Ivy League, and Tier 1 private and state colleges, Flex Education has the experience and knowledge to find the exact fit and to help students complete all aspects of the application process. From filling in forms to the all-important Personal Statements, we are there with expert assistance and guidance!"]
                        },
                        {
                            title: "SAT / ACT Preparation",
                            desc: ["Careful exam preparation can yield great dividends in final scores. Flex Education first helps students choose which exam(s) they will take and then creates a schedule for junior and senior high school years. We then support the entire process, including arranging tutoring to reinforce exam-taking strategies and subject knowledge."]
                        },
                        {
                            title: "College Tours",
                            desc: [
                                "In Spring of 2018, Flex Education began offering a tour of top colleges from Boston down through to New York City. These tours are small and very personalized and allow students to see firsthand the facilities, classes, and environments of some of the most famous colleges in the US!",
                                "transportation, accomodations, meals, and college walking tours and admissions presentations:",
                                [
                                    "Harvard University",
                                    "Tufts University",
                                    "Northeastern University",
                                    "Boston College",
                                    "Brown University",
                                    "Rhode Island School of Design",
                                    "Yale University",
                                    "Columbia University",
                                    "New York University",
                                ]
                            ]
                        }
                    ]
                },
                college: {
                    header: "International College Students",
                    headerDesc: "Flex Education offers personalized support services to identify, apply to, and thrive in a US college.",
                    desc: [
                        "Assessment and Recommendations",
                        [
                            "The process begins with a careful review of the high school graduate’s records, interests, and aptitudes. Flex Education will then recommend best-fit colleges and majors that will enable the student to reach his or her academic goals.",
                            "Once potential schools are selected, we assist with the applications, personal statements, referrals, transcript submissions, interviews, and documentation processes."
                        ],
                    ],
                    btn: "INTERNATIONAL STUDENT APPLICATION",
                    cardTitles: ["4 YEAR COLLEGES", "COMMUNITY COLLEGE TRANSFER OPTIONS (2+2)"],
                    collapseCards: [
                        {
                            title: "Major and Course Advising",
                            desc: ["Congratulations – you’ve been accepted! Now the fun part begins: choosing your major and structuring your studies. Working alongside your college advisors, Flex Education can help you choose courses and schedules that work for you today, with an eye toward future studies and career goals. We will make sure that you stay on track!"]
                        },
                        {
                            title: "Subject Tutoring",
                            desc: ["Studying in the US is a very different experience for many students and sometimes a little extra help is needed to attain satisfactory results. Flex Education provides complete, subject-based tutoring services that individualize learning and enable students to reach their grade goals."]
                        },
                        {
                            title: "California Community Colleges",
                            desc: ["California has the most extensive network of public 2-year colleges in the US. While well known for offering first-rate vocational programs, these schools are also becoming justly popular for their 4-year transfer programs, also known as 2+2."]
                        },
                        {
                            title: "2+2 Programs",
                            desc: [
                                "These 2+2 programs include transferable credits that will be accepted to University of California (UC) and California State (CS) schools as well as many out of state schools. Flex Education helps students:",
                                [
                                    "Select and apply to and appropriate college and transfer program",
                                    "Choose classes that will fulfill the requirements of major core and elective subjects",
                                ],
                                "Generally the student will spend 2 years at the community college and then transfer to a 4-year for the final two years. The undergraduate degree will be issued by the 4-year school."
                            ]
                        },
                        {
                            title: "A Better Option!",
                            desc: [
                                "Transfer programs are an excellent choice for many students because they offer an alternate path to some of the best colleges in the country when the student’s:",
                                [
                                    "High school GPA or courses are insufficient for the 4-year colleges of choice",
                                    "English skills require reinforcement",
                                    "Major is changing and additional prerequisites must be fulfilled",
                                    "Current 4-year studies are interrupted by poor grades that require remediation",
                                ],
                                "Transfer students often discover that they are admitted to much better 4-year colleges than they would have been applying directly from high school. This is possible because generally the community college classes are usually smaller and more focused on transfer success, which translates to excellent teaching and much better grades for many international students.",
                                "2+2 programs have also proven to be extremely cost-effective for many students."
                            ]
                        },
                        {
                            title: "Advising and Applications",
                            desc: [
                                "As with all our students, our 2+2 and community college remediation students receive the very best in major, course, and transfer advising; study support; and application assistance. Flex Education has extensive experience in making this approach work for many students."
                            ]
                        }
                    ]
                },
                application: {
                    header: "International Student Application",
                    headerDesc: "Apply for Flex Education programs here, whether you are planning to, or are currently attending a US college.",
                    studentForm: {
                        title: "STUDENT INFORMATION",
                        submitter: "Who is submitting application",
                        submitOptions: [
                            { value: 0, text: "Student" },
                            { value: 1, text: "Parent" },
                        ],
                        studentName: "Student Name: surname, first name",
                        parentName: "Parent Contact: surname, first name",
                        address1: "Address line1",
                        address2: "Address line2",
                        mailEmail: "Main Email Address",
                        altEmail: "Alt. Email Address",
                        city: "city",
                        phone: "Home Phone",
                        state: "State/Province",
                        zip: "Zip/Postal",
                        parentPhone: "Parent Cell Phone",
                        country: "Country",
                        countryOptions: [
                            { value: 0, text: "China" },
                            { value: 1, text: "USA" },
                            { value: 2, text: "Canada" },
                            { value: 3, text: "Other" }
                        ],
                        studentPhone: "Student Cell phone",
                    },
                    status: {
                        title: "Student Status: (select choice)",
                        options: [
                            { value: 0, text: "A citizen/resident currently studying in the US" },
                            { value: 1, text: "An international student planning to study in the US" },
                            { value: 2, text: "An international student already studying in the US" },
                        ]
                    },
                    statusForm: {
                        eduLevel: "Interest in starting US studies in:",
                        eduOptions: [
                            { value: 0, text: "Middle School" },
                            { value: 1, text: "High School" },
                            { value: 2, text: "College" },
                        ],
                        visaStatus: "Current Visa Status:",
                        visaOptions: [
                            { value: 0, text: "F1 - Current or Applied for" },
                            { value: 1, text: "B1 - Current or Applied for" },
                            { value: 2, text: "B2 - Current or Applied for" },
                            { value: 3, text: "US Citizen Living Abroad" },
                            { value: 4, text: "No Visa" },
                        ],
                        visaExpiry: "Visa Expiry Date",
                        beginYear: "Beginning Year",
                        semesterTitle: "Starting in...",
                        semesterOptions: [
                            { value: 0, text: "Fall" },
                            { value: 1, text: "Spring" }
                        ],
                        major: "Major"
                    },
                    educationForm: {
                        title: "EDUCATIONAL INFORMATION",
                        subTitle1: "Current Education Level & GPA",
                        eduLevel: "Current Education Level",
                        eduOptions: [
                            { value: 0, text: "Middle School" },
                            { value: 1, text: "High School" },
                            { value: 2, text: "College" },
                        ],
                        gradeLevel: "Current Grade Level",
                        gradeOptions: [
                            { value: 0, text: "Grade6" },
                            { value: 1, text: "Grade7" },
                            { value: 2, text: "Grade8" },
                            { value: 3, text: "Grade9" },
                            { value: 4, text: "Grade10" },
                            { value: 5, text: "Grade11" },
                            { value: 6, text: "Grade12" },
                            { value: 7, text: "Grade13" },
                            { value: 8, text: "College Freshman" },
                            { value: 9, text: "College Sophomore" },
                            { value: 10, text: "College Junior" },
                            { value: 11, text: "College Senior" },
                        ],
                        gpa: "Current GPA",
                        subTitle2: "Current School & Location",
                        school: "Current School Name",
                        country: "Country",
                        countryOptions: [
                            { value: 0, text: "China" },
                            { value: 1, text: "USA" },
                            { value: 2, text: "Canada" },
                            { value: 3, text: "Other" }
                        ],
                        address1: "Address Line 1",
                        address2: "Address Line 2",
                        city: "City",
                        state: "State/Province",
                        zip: "Zip/Postal",
                        interestTitle: "Choose what you are interested in...",
                        interestSubTitle: "Choose all that apply*",
                        interestSubjects: [
                            [
                                "College Advising",
                                "Subject Tutoring",
                                "Other"
                            ],
                            [
                                "College Care Packages",
                                "Appeals Assistance",
                                "Legal Assistance"
                            ]
                        ]
                    },
                    comment: {
                        title: "Additional Comments/Information",
                        placeHolder: "Enter additional information for your application"
                    },
                }
            },
            precollege: {
                header: "Pre–College Programs",
                headerDesc: "Start US college studies fully prepared, academically and culturally, with Flex Education Pre-College classes!",
                desc: [
                    "International high school and college students alike can benefit from our Pre-College classes and personalized tutoring services These classes are designed to:",
                    [
                        "Ease the transition for high school graduates into demanding US studies",
                        "Introduce and/or reinforce key skills for current college freshmen and sophomores to",
                        "greatly reduce confusion and stress",
                        "avoid possible academic infractions",
                        "improve grades, especially in the humanities",
                        "increase self-confidence and presentation skills",
                        "enhance research competency"
                    ],
                    "All studies include an assessment of the student’s abilities and needs. Current assignments may form the basis of study for students already attending college."
                ],
                cardTitles: ["SOME OF OUR MOST POPULAR AREAS OF STUDY INCLUDE:"],
                collapseCards: [
                    {
                        title: "Essay Writing Skills",
                        desc: ["From 5 paragraphs to 50 pages – everything you need to know to research, develop, organize, and write your best papers ever!"]
                    },
                    {
                        title: "Research and Citation",
                        desc: ["Did you know that you can easily fail a paper and even the whole course for an improper or missing citation? If you are unfamiliar with these and related copyright issues, these are the classes for you!",
                            "Properly cited works rely on correctly researched material. This class will help you to locate and verify sources for any subject that are wholly credible, consistent, and high-quality."]
                    },
                    {
                        title: "Analytical Reading and Critical Thinking Skills",
                        desc: ["Rhetorical analysis, argument evaluation, assumptions, reasoning, are just a few of the topics covered in these essential classes. Students learn to think more deeply about the work of others, and to express themselves more clearly, and completely."]
                    },
                    {
                        title: "College Success",
                        desc: ["A component of many other studies, college success implies tactics and skills that are key to a healthy, productive, and satisfying college experience. Learning to study effectively, present well, navigate major requirements, work with a diverse team, and adapt to cultural differences, are all subjects to be explored and addressed."]
                    },
                    {
                        title: "American English Verbal Fluency",
                        desc: ["There is no avoiding the truth: we all have accents! Flex Education helps international students who have had perhaps less exposure to American English, to improve speech clarity as well as verbal comprehension."]
                    }
                ]
            },
            k8students: {
                header: "K–8 Students",
                headerDesc: "Take the keys to K-8 success with Flex Education Reading Clubs, Tutoring, and Enrichment programs!",
                desc: [
                    { title: "Reading Clubs" },
                    "Developing a lifelong love of reading is key to academic success. Our fun and interactive reading clubs introduce young students to wonderful age-appropriate stories in a relaxed, but structured environment. As a supplementary way to strengthen skills or to engage advanced students, reading clubs provide the right learning experience.",
                    { title: "Tutoring" },
                    "One-on-one subject tutoring with our skilled, caring educators may be exactly what the young student needs to reach or retain grade level proficiency. Advanced learners will also appreciate Flex Education’s commitment to helping each child blossom.",
                    { title: "Enrichment Classes" },
                    "Stay tuned for exciting, new enrichment classes coming soon!",
                ]
            },
            tutoring: {
                header: "Individual Tutoring",
                headerDesc: "Improve skills, explore a passion, reach goals at all levels, from K-College, with Flex Education personalized Tutoring Services!",
                cards: [
                    {
                        title: "A Passion for Teaching",
                        desc: ["Flex Education offers tutoring to help students in all subjects, from elementary school through to college. Our tutors are carefully selected for their subject competence, teaching style, and personality.We always carefully match tutors and students, and then monitor the student’s progress to ensure satisfactory results."]
                    },
                    {
                        title: "Survive… or Thrive?!",
                        desc: [
                            "Tutoring can mean the difference between a student just getting by or reaching his or her potential. Flex Education has helped dozens of students thrive with personalized tutoring to:",
                            [
                                "Reinforce foundational concepts",
                                "Prepare for tests and exams",
                                "Complete major assignments and projects",
                                "Improve grades",
                                "Complete applications",
                            ]
                        ]
                    },
                    {
                        title: "Team Win!",
                        desc: ["Tutoring is a partnership between the tutor, parents, and student, and all are involved in assessing the student’s abilities and goals. Flex Education will track the student’s achievements and report to the parent’s as needed."]
                    },
                    {
                        title: "In-Person or Video Conferencing!",
                        desc: ["Tutoring normally happens in our local office, but for older students we also offer the option of being tutored online. This is ideal when time or distance does not allow for in-person interaction. We provide access to our online video-conferencing with a free and easy to use app that offers chat, whiteboard and screen sharing functions to enhance the learning experience."]
                    }
                ]
            }
        },
        about: {
            header: "Flex Education",
            headerDesc: "Students thrive with highly personalized services delivered by our hand-picked advisers and educators!",
            cards: [
                {
                    type: "wide-card",
                    img: "/images/about_puzzle-400x300.jpg",
                    title: "Student Success – Fitting the Pieces Together!",
                    desc: ["Flex Education has worked with over 200 students since 2007. More than just “academic advising”, the Flex Education team considers each student as a complete person with unique dreams, abilities, family life, and personal style. Every Flex Education student is given the same individualized attention to encourage academic and personal growth. Our students thrive because we address and support each piece of the whole person during this exciting time of development."]
                },
                {
                    type: "tall-card",
                    reverse: true,
                    img: "/images/about_tutor-440x550.jpg",
                    title: "Personalized Attention – Wherever You Are!",
                    desc: [
                        "Specialized programs give personalized attention to each student, near or far, via one-on-one meetings and video conferencing. Using a secure, private online service, our students can easily download an easy to use app that allows us to communicate via:",
                        [
                            "Video",
                            "Chat",
                            "Screen Sharing",
                            "Document Sharing",
                        ],
                        "Regardless of where you may be, we are always close at hand!"
                    ]
                },
                {
                    type: "wide-card",
                    img: "/images/SilValley-Sunrise-700x400.jpg",
                    title: "Location, Location, Location!",
                    desc: ["Headquartered in the beautiful Santa Cruz mountains, Flex Education takes advantage of its strategic location between the San Francisco Bay and Monterey Bay areas. This is the starting point for our students to explore all of California while easily accessing some of the best academic facilities, outdoor activities, and cultural attractions in the world. But we don’t rest there; our students have studied in top tier schools and frequently visit friends, families, and attractions throughout the US and Canada"]
                }
            ]
        },
        contact: {
            header: "Contact Us",
            headerDesc: "New to Flex Education? Reach out, we'd love to answer any questions!",
            title: "Talk to us!",
            name: "Your Name(required)",
            nameHolder: "Your Name",
            email: "Password(required)",
            emailHolder: "email@example.com",
            subject: "Subject",
            message: "Your Message",
            info: "CONTACT INFOMATION",
            btn: "SUBMIT",
            address: [
                "216-C Mt. Hermon Road",
                "Scotts Valley, CA 95066 USA"
            ],
            phone: [
                "1.831.316.7627"
            ],
            time: "M – F: 9:00-17:00",
        },
        drivingTest: {
            handbookEN: "Handbook in English",
            handbookCN: "Handbook in Chinese",
        },
        videos: {
            titles: [
                "Car Theft Prevention Tips",
                "Common Driving Myths & Misconceptions",
                "Get a Grip on the Proper Way to Hold Your Steering Wheel",
                "How to avoid road rage",
                "How to drive in the Snow",
                "How To Drive To Reduce A Traffic Jam",
                "Importance Of The Move Over Law",
                "Learn About Lost Car Facts",
                "Most Common Driving Mistakes To Avoid",
                "Share the Road",
                "Vehicle Warning Lights Send Messages You Can’t Ignore",
                "What to Do After a Collison",
                "What to Do if Animal Runs in Front of You",
                "What to Do if Your Car Breaks Down",
            ],
            listTitle: "Video List",
        }
    },
}