import React from "react";
import { Link } from "react-router-dom";
import './Navigator.scss';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import LangContext from '../../context';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';

const StyledMenu = withStyles({})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export default function Navigator() {
  const [aboutMenu, setAboutMenu] = React.useState(null);
  const [programMenu, setProgramMenu] = React.useState(null);
  const [resourcesMenu, setResourcesMenu] = React.useState(null);
  const [coursesMenu, setCoursesMenu] = React.useState(null);
  const [drawer, setDrawer] = React.useState(false);
  const { locale } = React.useContext(LangContext);

  const navUrls = [["/"], ["/about-us", "ABOUT"], ["/programs", "PROGRAMS"], ["/contact"], ["#", "COURSES"], ["#", "RESOURCES"]]
  const aboutSubUrls = [
    "/about-us",
  ]
  const programSubUrls = [
    "/programs/international-students",
    "/programs/international-students/international-college",
    "/programs/international-students/international-highschool",
    "/programs/international-students/international-application",
    "/programs/pre-college-programs/",
    "/programs/k-8-students",
    "/programs/tutoring",
    "/programs/summer-camp"
  ]
  const resourcesSubUrls = [
    "/driving-test",
  ]
  const coursesSubUrls = [
    "/AAA-videos",
  ]
  const subUrlTitles = [[], locale.menuAbout, locale.menuPrograms, [], locale.menuCourses, locale.menuResources]
  const subUrls = [[], aboutSubUrls, programSubUrls, [], coursesSubUrls, resourcesSubUrls]

  const menuItemActions = {
    "ABOUT": setAboutMenu,
    "PROGRAMS": setProgramMenu,
    "RESOURCES": setResourcesMenu,
    "COURSES": setCoursesMenu,
  }

  const handleClick = name => event => {
    if (name)
      menuItemActions[name](event.currentTarget);
  }

  const handleClose = name => state => {
    menuItemActions[name](null);
  }


  return (
    <div className="navigator">
      <Button className="fixedMenu" onClick={e => setDrawer(true)}><MenuIcon style={{ fontSize: 40 }} /></Button>
      <Drawer open={drawer} onClose={e => setDrawer(false)}>
        <div
          role="presentation"
          onClick={e => setDrawer(false)}
          onKeyDown={e => setDrawer(false)}>
          <List>
            {locale.nav.map((text, i) => (
              <React.Fragment key={text}>
                <Link key={navUrls[i][0]} to={navUrls[i][0]}>
                  <ListItem button>
                    {text}
                  </ListItem>
                </Link>
                {subUrlTitles[i].map((e, j) => (
                  <Link key={j} to={subUrls[i][j]}>
                    <ListItem button>
                      {e.indexOf("#") === -1 ? null : <ArrowRightIcon />}
                      {e.replace("#", "")}
                    </ListItem>
                  </Link>
                ))
                }
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </div>
      </Drawer>
      <Breadcrumbs className="bread-crumbs" separator="·" aria-label="breadcrumb">
        {locale.nav.map((e, i) => (
          <Link key={navUrls[i][0]} to={navUrls[i][0]} onClick={handleClick(navUrls[i][1])}>
            {e}
          </Link>
        ))}
      </Breadcrumbs>
      <StyledMenu
        className="about-menu"
        anchorEl={aboutMenu}
        keepMounted
        open={Boolean(aboutMenu)}
        onClose={handleClose("ABOUT")}>
        <Link color="inherit" to="#">
          <MenuItem onClick={handleClose("ABOUT")}>
            {locale.menuAbout}
          </MenuItem>
        </Link>
      </StyledMenu>
      <StyledMenu
        className="program-menu"
        anchorEl={programMenu}
        keepMounted
        open={Boolean(programMenu)}
        onClose={handleClose("PROGRAMS")}>
        {locale.menuPrograms.map((e, i) => (
          <Link key={i} to={programSubUrls[i]}>
            <MenuItem className="menu-item" onClick={handleClose("PROGRAMS")}>
              {e.indexOf("#") === -1 ? null : <PlaylistPlayIcon />}
              {e.replace("#", "")}
            </MenuItem>
          </Link>
        ))}
      </StyledMenu>
      <StyledMenu
        className="resources-menu"
        anchorEl={resourcesMenu}
        keepMounted
        open={Boolean(resourcesMenu)}
        onClose={handleClose("RESOURCES")}>
        {locale.menuResources.map((e, i) => (
          <Link key={i} to={resourcesSubUrls[i]}>
            <MenuItem className="menu-item" onClick={handleClose("RESOURCES")}>
              {e}
            </MenuItem>
          </Link>
        ))}
      </StyledMenu>
      <StyledMenu
        className="courses-menu"
        anchorEl={coursesMenu}
        keepMounted
        open={Boolean(coursesMenu)}
        onClose={handleClose("COURSES")}>
        {locale.menuCourses.map((e, i) => (
          <Link key={i} to={coursesSubUrls[i]}>
            <MenuItem className="menu-item" onClick={handleClose("COURSES")}>
              {e}
            </MenuItem>
          </Link>
        ))}
      </StyledMenu>
    </div>
  )
}