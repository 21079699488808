import React from "react";
import './SubHeader.scss';

export default function SubHeader(props) {
  return (
    <div className="sub-header">
        <img className="bg-img" src={props.img || "/images/parallax_intnl.jpg"}></img>
        <div className="sub-header-title">{props.title}</div>
        <div className="sub-header-content">{props.content}</div>
    </div>
  )
}
