import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import "video-react/dist/video-react.css";
import { Player } from 'video-react';
import "./AAAVideos.scss";
import LangContext from '../../../context';

export default function AAAVideos(props) {
  const videoUrlList = [
    "https://flex-static.oss-cn-hongkong.aliyuncs.com/Car%20Theft%20Prevention%20Tips.mp4",
    "https://flex-static.oss-cn-hongkong.aliyuncs.com/Common%20Driving%20Myths%20%26%20Misconceptions.mp4",
    "https://flex-static.oss-cn-hongkong.aliyuncs.com/Get%20a%20Grip%20on%20the%20Proper%20Way%20to%20Hold%20Your%20Steering%20Wheel.mp4",
    "https://flex-static.oss-cn-hongkong.aliyuncs.com/How%20To%20Drive%20To%20Reduce%20A%20Traffic%20Jam.mp4",
    "https://flex-static.oss-cn-hongkong.aliyuncs.com/How%20to%20Avoid%20Road%20Rage.mp4",
    "https://flex-static.oss-cn-hongkong.aliyuncs.com/How%20to%20Drive%20in%20the%20Snow.mp4",
    "https://flex-static.oss-cn-hongkong.aliyuncs.com/Importance%20Of%20The%20Move%20Over%20Law.mp4",
    "https://flex-static.oss-cn-hongkong.aliyuncs.com/Learn%20About%20Lost%20Car%20Facts.mp4",
    "https://flex-static.oss-cn-hongkong.aliyuncs.com/Most%20Common%20Driving%20Mistakes%20To%20Avoid.mp4",
    "https://flex-static.oss-cn-hongkong.aliyuncs.com/Share%20the%20Road.mp4",
    "https://flex-static.oss-cn-hongkong.aliyuncs.com/Vehicle%20Warning%20Lights%20Send%20Messages%20You%20Can%E2%80%99t%20Ignore.mp4",
    "https://flex-static.oss-cn-hongkong.aliyuncs.com/What%20to%20Do%20After%20a%20Collison.mp4",
    "https://flex-static.oss-cn-hongkong.aliyuncs.com/What%20to%20Do%20if%20Animal%20Runs%20in%20Front%20of%20You.mp4",
    "https://flex-static.oss-cn-hongkong.aliyuncs.com/What%20to%20Do%20if%20Your%20Car%20Breaks%20Down.mp4",
  ]

  const {videos} = React.useContext(LangContext).locale;
  const [videoUrl, setVideoUrl] = React.useState(videoUrlList[0])
  const [titleIndex, setTitleIndex] = React.useState(0)

  const changeVideo = index => {
    setVideoUrl(videoUrlList[index])
    setTitleIndex(index)
    setTimeout( () => window.scrollTo(0,1), 1)
  }


    return (
      <div className="videos">
        <div className="videos-content">
          <div className="videos-content-title">
            <h4>{videos.titles[titleIndex]}</h4>
          </div>
          <div className="videos-content-player">
            <Player src={videoUrl}/>
          </div>
          <div className="videos-drawer">
          <ExpansionPanel className="expand-drawer">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>{videos.listTitle}</p>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List>
                {videos.titles.map( (text, index) => (
                  <div key={index}>
                    <ListItem button onClick={ e => changeVideo(index)}>
                      <ListItemIcon><OndemandVideoIcon/></ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItem>
                    <Divider/>
                  </div>
                ))}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
          <div className="videos-content-comments">
            <p>Copyright © 2015 AAA, All Rights Reserved.</p>
            <p>“Driver Education and Training Videos.” AAA NewsRoom, AAA, 10 May 2013, newsroom.aaa.com/for-media/driver-education-and-training-videos/.</p>
          </div>
        </div>
        <div className="videos-drawer">
          <List className="list-drawer">
            {videos.titles.map( (text, index) => (
              <div key={index}>
                <ListItem button onClick={ e => changeVideo(index)}>
                  <ListItemIcon><OndemandVideoIcon/></ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
                <Divider/>
              </div>
            ))}
          </List>
        </div>
      </div>
    );
}