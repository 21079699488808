import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import { Form } from "react-bootstrap";
import {
    Link,
    Redirect
} from "react-router-dom";
import "./Register.scss";
import LangContext from '../../context';

export default function Register(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    var [redirectToLogin, setRedirectToLogin] = useState(false);
    const {register} = React.useContext(LangContext).locale;

    function validateForm() {
        return username.length > 0 && password.length > 0;
    }
    
    function handleSubmit(event) {
        event.preventDefault();
        //console.log("handle account registration");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: username, password: password }),
            credentials: 'include'
        };
        fetch('/users/register', requestOptions)
            .then((response) => {
                if (response.status === 200) {
                    console.log('Succesfully registered user!');
                    setRedirectToLogin(true);
                    console.log("redirectToLogin is ", redirectToLogin)
                }
            })
            .catch(error => {
                console.error('Error registering user.', error);
            });
    }

    return redirectToLogin? (
        <Redirect 
            to={{ 
                pathname: "/users/login" 
            }}
        />
    ) : (
        <div className="Register">
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="username">
                    <Form.Label>{register.username}</Form.Label>
                    <Form.Control
                        autoFocus
                        type="username"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>{register.password}</Form.Label>
                    <Form.Control
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                    />
                </Form.Group>
                <Button variant="contained" disabled={!validateForm()} type="submit">
                    {register.btn}
                </Button>
                <p className="already-registered">
                    {register.hint} <Link color="inherit" to="/login"> {register.nav} </Link>
                </p>
            </Form>
        </div>
    );
}