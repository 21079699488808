import React from "react";
import './About.scss';
import SubHeader from '../../components/SubHeader/SubHeader';
import LangContext from '../../context';

function AboutCard(props) {
    return (
        <div className={`about-card-box ${props.className}`}>
            {!props.reverse ||
            <div className="about-card-box-image">
                <img src={props.imgSrc}/>
            </div>}
            <div className="about-card-box-text">
                <div className="about-card-box-text-title">
                {props.title}
                </div>
                <div className="about-card-box-text-content">
                {props.children}
                </div>
            </div>
            {props.reverse ||
            <div className="about-card-box-image">
                <img src={props.imgSrc}/>
            </div>}
        </div>
    )
}

export default function About() {
  const {about} = React.useContext(LangContext).locale;
  return (
    <div className="about">
        <SubHeader title={about.header} content={about.headerDesc}/>
        <div className="about-card">
            {about.cards.map( (e,i) => (
              <AboutCard
                key={i}
                className={e.type}
                title={e.title}
                reverse={e.reverse}
                imgSrc={e.img}>
                {e.desc.map( (ele,i) => {
                  if (Array.isArray(ele)) 
                    return (
                      <ul key={i}>{ele.map( listEle => (<li key={listEle}>{listEle}</li>))}</ul>
                    )
                  else return <p key={i}>{ele}</p>
                })}
              </AboutCard>
            ))}
        </div>
    </div>
  )
}
