import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
  Redirect,
  useHistory
} from "react-router-dom";
import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import './App.scss';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Navigator from '../../components/Navigator/Navigator';
import Home from '../Home/Home';
import About from '../About/About';
import Login from '../Login/Login';
import Register from "../Register/Register";
import Contact from "../Contact/Contact";
import Programs from "../Programs/Programs";
import AAAVideos from "../Videos/AAAVideos/AAAVideos";
import { IStudents } from "../Programs/InternationalStudents/InternationalStudents";
import { ICollege } from "../Programs/InternationalStudents/College/College";
import { IHighschool } from "../Programs/InternationalStudents/Highschool/Highschool";
import { IApplication } from "../Programs/InternationalStudents/Application/Application";
import { PCollege } from "../Programs/PreCollegePrograms/PreCollegePrograms";
import { K8Students } from "../Programs/K8Students/K8Students";
import { Tutoring } from "../Programs/Tutoring/Tutoring";
import { auth } from "../../utils/userAuth.js";
import { LANG } from "../../config/lang";
import LangContext, { LangProvider, LangConsumer } from "../../context";
import DrivingTest from "../DrivingTest/DrivingTest";
import SummerCamp from "../Programs/SummerCamp/SummerCamp";

const paths2routes = paths => {
  let routes = []
  for (let [key, value] of Object.entries((paths))) {
    routes.push(
      <Route exact key={key} path={key}>
        {value}
      </Route>
    )
  }
  return routes
}

const AuthButton = withRouter(({ history }) => (
  auth.isAuthenticated ? (
    <span>
      <span><strong>{auth.username}</strong></span>
      <Button onClick={() => {
        auth.signout(() => history.push('/'))
      }}>
        <LangConsumer>
          {props => {
            return props.locale.signout
          }}
        </LangConsumer>
      </Button>
    </span>
  ) : (
    <span>
      <Link color="inherit" to="/users/login">
        <LangConsumer>
          {props => {
            return (<Button>{props.locale.signin}</Button>)
          }}
        </LangConsumer>
      </Link>
      <Link color="inherit" to="/users/register">
        <LangConsumer>
          {props => {
            return (<Button>{props.locale.signup}</Button>)
          }}
        </LangConsumer>
      </Link>
    </span>
  )
));

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { locale, setLocale } = React.useContext(LangContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (lang) => {
    setAnchorEl(null);
    if (lang == "enUS" || lang == "zhCN")
      setLocale(LANG[lang]);
  };

  return (
    <div className="app-header">
      <div className="app-header-wrapper">
        <div>
          <span><PhoneIcon className="icon" />{locale.contact.phone[0]}</span>
          <span><MailIcon className="icon" />contact@flexeducationinc.com</span>
        </div>
        <div className="app-header-right">
          <Button aria-controls="lang-menu" aria-haspopup="true" onClick={handleClick}>
            {locale.toggleLocale}<ExpandMoreIcon />
          </Button>
          <Menu
            id="lang-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={e => handleClose("zhCN")}>简体中文</MenuItem>
            <MenuItem onClick={e => handleClose("enUS")}>English</MenuItem>
          </Menu>
          {/* <AuthButton /> */}
          <a href="mailto:contact@flexeducationinc.com"><MailIcon className="icon" /></a>
        </div>
      </div>
    </div>
  )
}

const HomeIcon = () => {
  const history = useHistory();

  function handleClick() {
    history.push("/home");
  }

  return (
    <img
      src="/images/FlexLogo-_header.png"
      alt="Icon"
      width="914"
      height="268"
      onClick={handleClick}
    />
  );
}

export default () => {
  const paths = {
    "/": <Home />,
    "/about-us": <About />,
    "/programs": <Programs />,
    "/contact": <Contact />,
    "/users/login": <Login />,
    "/users/register": <Register />,
    "/programs/international-students": <IStudents />,
    "/programs/international-students/international-highschool": <IHighschool />,
    "/programs/international-students/international-college": <ICollege />,
    "/programs/international-students/international-application": <IApplication />,
    "/programs/pre-college-programs": <PCollege />,
    "/programs/k-8-students": <K8Students />,
    "/programs/tutoring": <Tutoring />,
    "/AAA-videos": <AAAVideos />,
    "/driving-test": <DrivingTest />,
    "/programs/summer-camp": <SummerCamp />
  }

  const [locale, setLocale] = React.useState(LANG.zhCN)

  React.useEffect(() => {
    // Update the document title using the browser API
    document.title = locale.title;
  });

  return (
    <LangProvider value={{ locale, setLocale }}>
      <Router>
        <div className="app">
          <Header />
          <div className="app-navigator">
            <HomeIcon />
            <Navigator />
          </div>
          <Switch>
            {paths2routes(paths)}
            <Route render={() => <Redirect to={{ pathname: "/" }} />} />
          </Switch>
          <div className="app-footer">
            <div>Advanced Academics and Cultural Programs</div>
            <div>Copyright © 2019 - All Right Reserved - Flex Education, Inc.</div>
          </div>
        </div>
      </Router>
    </LangProvider>
  );
}
