import React from "react";
import "./College.scss";
import SubHeader from "../../../../components/SubHeader/SubHeader";
import CollapseCard from "../../../../components/CollapseCard/CollapseCard";
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";
import LangContext from '../../../../context';

export function ICollege() {
  const {college} = React.useContext(LangContext).locale.programs.istudents;
  return (
      <div className="icollege">
        <SubHeader title={college.header} content={college.headerDesc}/>
        <div className="icollege-desc">
            <div>
                {college.desc.map( (ele,i) => {
                  if (Array.isArray(ele)) 
                    return (
                      <ul key={i}>{ele.map( listEle => (<li key={listEle}>{listEle}</li>))}</ul>
                    )
                  else return <h4 key={i}>{ele}</h4>
                })}
                <div className="icollege-button">
                <Link to="/programs/international-students/international-application">
                    <Button variant="contained">
                        {college.btn}
                    </Button>   
                </Link>          
                </div>
            </div>
            <div className="image">
                <img
                    src="/images/college-students3-700x400.jpg"
                    alt="programs"/>
            </div>
        </div>
        <div className="icollege-packages">
            <div>
                <h5>{college.cardTitles[0]}</h5>
                {college.collapseCards.slice(0,2).map( (ele, i) => (
                  <CollapseCard key={i} title={ele.title} desc={ele.desc}/>
                ))}
            </div>
            <div>
                <h5>{college.cardTitles[1]}</h5>
                {college.collapseCards.slice(2).map( (ele, i) => (
                  <CollapseCard key={i} title={ele.title} desc={ele.desc}/>
                ))}
            </div>
        </div>
      </div>
  );
}
