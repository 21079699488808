import React from "react";
import "./K8Students.scss";
import SubHeader from "../../../components/SubHeader/SubHeader";
import LangContext from "../../../context";

export function K8Students() {
  const {k8students} = React.useContext(LangContext).locale.programs;
  return (
      <div className="k8student">
        <SubHeader title={k8students.header} content={k8students.headerDesc} img={"/images/parallax_k8_2.jpg"}/>
        <div className="k8student-desc">
            <div>
              {k8students.desc.map( (ele,i) => {
                if (ele instanceof Object) 
                  return (
                    <h5 key={i}>{ele.title}</h5>
                  )
                else return <p key={i}>{ele}</p>
              })}
            </div>
            <div className="image">
                <img
                    src="/images/k8_programs-400x300.jpg"
                    alt="program image"/>
            </div>
        </div>
      </div>
  );
}
