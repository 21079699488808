import React from 'react';

const SummerCamp = () => {
    return (
        <div>
            <h1>Coming Soon 即将到来</h1>
            {/* Add your content here */}
        </div>
    );
};

export default SummerCamp;
